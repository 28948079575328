import { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// import socketIOClient from 'socket.io-client';

import { selectCurrentUser } from './Redux/user/user.selectors';
import { checkUserSession } from './Redux/user/user.actions';

import LoginPage from './Pages/login-page/login-page.component';
import Dashboard from './Pages/dashboard/dashboard-page.component';
import Alert from './Components/alert/alert.component';
import Loading from './Components/loading/loading.component';

// export const socket = socketIOClient();

function App({ user, checkUserSession, appLoading }) {
  useEffect(() => {
    checkUserSession();
  }, [checkUserSession]);

  if (appLoading)
    return (
      <Loading
        type="infinite spinner"
        loadingText="Please wait while the app is loading"
        fullScreen
        containerStyles={{ backgroundColor: '#eee' }}
      />
    );

  return (
    <>
      <Alert />
      <Switch>
        <Route
          exact
          path="/"
          render={() => (user ? <Redirect to="/dashboard" /> : <LoginPage />)}
        />
        <Route
          exact
          path="/login"
          render={() => (user ? <Redirect to="/" /> : <LoginPage />)}
        />
        <Route
          path="/dashboard"
          render={(props) =>
            user ? <Dashboard {...props} /> : <Redirect to="/login" />
          }
        />
      </Switch>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
  appLoading: (state) => state.app.appLoading,
});

const mapDispatchToProps = (dispatch) => ({
  checkUserSession: () => dispatch(checkUserSession()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
