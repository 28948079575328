import EngagementActionTypes from './engagements.types';

const INITIAL_STATE = {
    totalEngagements: 0,
    totalOngoingEngagements: 0,
    totalSuspendedEngagements: 0,
    totalCompletedEngagements: 0,
    recentEngagements: [],
    gettingRecentEngagements: true,
    gottenRecentEngagements: false,
    addingEngagement: false,
    addedEngagement: false,
    engagementToEdit: null,
    updatingEngagement: false,
    searchingEngagements: false,
    searchedEngagements: false,
    searchObject: {},
    searchResults: [],
    totalFound: 0,
    totalShown: 0,
    currentPage: 1,
    loadingMoreEngagements: false,
};

const engagementReducer = (currentState = INITIAL_STATE, action) => {
    switch (action.type) {
        case EngagementActionTypes.GET_RECENT_ENGAGEMENTS_START:
            return {
                ...currentState,
                gettingRecentEngagements: true,
            };
        case EngagementActionTypes.ADD_ENGAGEMENT_START:
            return {
                ...currentState,
                addingEngagement: true,
                addedEngagement: false,
            };
        case EngagementActionTypes.UPDATE_ENGAGEMENT_START:
            return {
                ...currentState,
                updatingEngagement: true,
            };
        case EngagementActionTypes.SEARCH_ENGAGEMENTS_START:
            return {
                ...currentState,
                searchingEngagements: true,
                searchedEngagements: false,
                searchObject: {...action.payload },
                totalFound: 0,
                totalShown: 0,
                currentPage: 1,
            };
        case EngagementActionTypes.LOAD_MORE_ENGAGEMENTS_START:
            return {
                ...currentState,
                loadingMoreEngagements: true,
                currentPage: action.payload.page,
            };

        case EngagementActionTypes.GET_RECENT_ENGAGEMENTS_SUCCESS:
            return {
                ...currentState,
                recentEngagements: [...action.payload.engagements],
                totalEngagements: action.payload.totalEngagements,
                totalOngoingEngagements: action.payload.totalOngoingEngagements,
                totalSuspendedEngagements: action.payload.totalSuspendedEngagements,
                totalCompletedEngagements: action.payload.totalCompletedEngagements,
                gettingRecentEngagements: false,
                gottenRecentEngagements: true,
            };
        case EngagementActionTypes.ADD_ENGAGEMENT_SUCCESS:
            return {
                ...currentState,
                totalEngagements: currentState.totalEngagements + 1,
                totalOngoingEngagements: currentState.totalOngoingEngagements + 1,
                recentEngagements: [
                    action.payload,
                    ...currentState.recentEngagements.filter((_, i) => i < 9),
                ],
                addingEngagement: false,
                addedEngagement: true,
            };
        case EngagementActionTypes.UPDATE_ENGAGEMENT_SUCCESS:
            return {
                ...currentState,
                updatingEngagement: false,
                engagementToEdit: null,
                recentEngagements: [
                    ...currentState.recentEngagements.map((el) => {
                        if (el._id !== action.payload._id) return {...el };

                        return {...action.payload };
                    }),
                ],
            };
        case EngagementActionTypes.SEARCH_ENGAGEMENTS_SUCCESS:
            return {
                ...currentState,
                searchingEngagements: false,
                searchedEngagements: true,
                searchResults: [...action.payload.data.data],
                totalFound: action.payload.totalDocuments,
                totalShown: action.payload.results,
            };
        case EngagementActionTypes.LOAD_MORE_ENGAGEMENTS_SUCCESS:
            return {
                ...currentState,
                loadingMoreEngagements: false,
                searchResults: [
                    ...currentState.searchResults,
                    ...action.payload.data.data,
                ],
                totalShown: currentState.totalShown + action.payload.results,
            };

        case EngagementActionTypes.SET_ADDED_ENGAGEMENT_TO_FALSE:
            return {
                ...currentState,
                addedEngagement: false,
            };
        case EngagementActionTypes.SET_ENGAGEMENT_TO_EDIT:
            return {
                ...currentState,
                engagementToEdit: action.payload,
            };
        case EngagementActionTypes.CLEAR_ENGAGEMENTS_SEARCH:
            return {
                ...currentState,
                searchedEngagements: false,
                searchObject: {},
                searchResults: [],
                totalFound: 0,
            };
        case EngagementActionTypes.LOAD_LESS_ENGAGEMENTS:
            const filtered = currentState.searchResults.filter((_, i) => i < 10);

            return {
                ...currentState,
                currentPage: 1,
                searchResults: [...filtered],
                totalShown: filtered.length,
            };

        case EngagementActionTypes.GET_RECENT_ENGAGEMENTS_FAILURE:
            return {
                ...currentState,
                gettingRecentEngagements: false,
                gottenRecentEngagements: false,
                recentEngagements: [],
                totalEngagements: 0,
                totalOngoingEngagements: 0,
                totalSuspendedEngagements: 0,
                totalCompletedEngagements: 0,
            };
        case EngagementActionTypes.ADD_ENGAGEMENT_FAILURE:
            return {
                ...currentState,
                totalEngagements: currentState.totalEngagements,
                totalOngoingEngagements: currentState.totalOngoingEngagements,
                recentEngagements: [...currentState.recentEngagements],
                addingEngagement: false,
                addedEngagement: false,
            };
        case EngagementActionTypes.UPDATE_ENGAGEMENT_FAILURE:
            return {
                ...currentState,
                updatingEngagement: false,
                engagementToEdit: currentState.engagementToEdit,
                recentEngagements: [...currentState.recentEngagements],
            };
        case EngagementActionTypes.SEARCH_ENGAGEMENTS_FAILURE:
            return {
                ...currentState,
                searchingEngagements: currentState.searchingEngagements,
                searchedEngagements: currentState.searchedEngagements,
                searchObject: {...currentState.searchObject },
                totalFound: currentState.totalFound,
            };
        case EngagementActionTypes.LOAD_MORE_ENGAGEMENTS_FAILURE:
            return {
                ...currentState,
                loadingMoreEngagements: false,
                currentPage: action.payload,
                searchResults: [...currentState.searchResults],
                totalShown: currentState.totalShown,
            };

        default:
            return currentState;
    }
};

export default engagementReducer;