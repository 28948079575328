const ClientActionTypes = {
    GET_RECENT_CLIENTS_START: 'GET_RECENT_CLIENTS_START',
    GET_RECENT_CLIENTS_SUCCESS: 'GET_RECENT_CLIENTS_SUCCESS',
    GET_RECENT_CLIENTS_FAILURE: 'GET_RECENT_CLIENTS_FAILURE',
    ADD_CLIENT_START: 'ADD_CLIENT_START',
    ADD_CLIENT_SUCCESS: 'ADD_CLIENT_SUCCESS',
    ADD_CLIENT_FAILURE: 'ADD_CLIENT_FAILURE',
    SET_ADDED_TO_FALSE: 'SET_ADDED_TO_FALSE',
    UPDATE_CLIENT_START: 'UPDATE_CLIENT_START',
    UPDATE_CLIENT_SUCCESS: 'UPDATE_CLIENT_SUCCESS',
    UPDATE_CLIENT_FAILURE: 'UPDATE_CLIENT_FAILURE',
    SET_UPDATED_TO_FALSE: 'SET_UPDATED_TO_FALSE',
    SEARCH_CLIENTS_START: 'SEARCH_CLIENTS_START',
    SEARCH_CLIENTS_SUCCESS: 'SEARCH_CLIENTS_SUCCESS',
    SEARCH_CLIENTS_FAILURE: 'SEARCH_CLIENTS_FAILURE',
    CLEAR_SEARCH_CLIENTS: 'CLEAR_SEARCH_CLIENTS',
    LOAD_MORE_CLIENTS_START: 'LOAD_MORE_CLIENTS_START',
    LOAD_MORE_CLIENTS_SUCCESS: 'LOAD_MORE_CLIENTS_SUCCESS',
    LOAD_MORE_CLIENTS_FAILURE: 'LOAD_MORE_CLIENTS_FAILURE',
    LOAD_LESS_CLIENTS: 'LOAD_LESS_CLIENTS',
};

export default ClientActionTypes;