const EngagementActionTypes = {
    GET_RECENT_ENGAGEMENTS_START: 'GET_RECENT_ENGAGEMENTS_START',
    GET_RECENT_ENGAGEMENTS_SUCCESS: 'GET_RECENT_ENGAGEMENTS_SUCCESS',
    GET_RECENT_ENGAGEMENTS_FAILURE: 'GET_RECENT_ENGAGEMENTS_FAILURE',
    ADD_ENGAGEMENT_START: 'ADD_ENGAGEMENT_START',
    ADD_ENGAGEMENT_SUCCESS: 'ADD_ENGAGEMENT_SUCCESS',
    ADD_ENGAGEMENT_FAILURE: 'ADD_ENGAGEMENT_FAILURE',
    SET_ADDED_ENGAGEMENT_TO_FALSE: 'SET_ADDED_ENGAGEMENT_TO_FALSE',
    SET_ENGAGEMENT_TO_EDIT: 'SET_ENGAGEMENT_TO_EDIT',
    UPDATE_ENGAGEMENT_START: 'UPDATE_ENGAGEMENT_START',
    UPDATE_ENGAGEMENT_SUCCESS: 'UPDATE_ENGAGEMENT_SUCCESS',
    UPDATE_ENGAGEMENT_FAILURE: 'UPDATE_ENGAGEMENT_FAILURE',
    SEARCH_ENGAGEMENTS_START: 'SEARCH_ENGAGEMENTS_START',
    SEARCH_ENGAGEMENTS_SUCCESS: 'SEARCH_ENGAGEMENTS_SUCCESS',
    SEARCH_ENGAGEMENTS_FAILURE: 'SEARCH_ENGAGEMENTS_FAILURE',
    CLEAR_ENGAGEMENTS_SEARCH: 'CLEAR_ENGAGEMENTS_SEARCH',
    LOAD_MORE_ENGAGEMENTS_START: 'LOAD_MORE_ENGAGEMENTS_START',
    LOAD_MORE_ENGAGEMENTS_SUCCESS: 'LOAD_MORE_ENGAGEMENTS_SUCCESS',
    LOAD_MORE_ENGAGEMENTS_FAILURE: 'LOAD_MORE_ENGAGEMENTS_FAILURE',
    LOAD_LESS_ENGAGEMENTS: 'LOAD_LESS_ENGAGEMENTS',
};

export default EngagementActionTypes;