import { Container, Spinner, BouncyDots, LoadingText } from './loading.styles';

const chooseType = (type) => {
  switch (type) {
    case 'infinite spinner':
      return <Spinner />;
    case 'bouncy dots':
      return <BouncyDots />;

    default:
      return <Spinner />;
  }
};

const Loading = ({
  children,
  type,
  loadingText,
  fullScreen,
  containerStyles,
}) => (
  <Container fullScreen={fullScreen} style={{ ...containerStyles }}>
    {chooseType(type)}

    {loadingText && <LoadingText>{loadingText}</LoadingText>}

    {children && children}
  </Container>
);

export default Loading;
