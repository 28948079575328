import { put, takeLatest, all, call } from 'redux-saga/effects';
import axios from 'axios';

import UserActionTypes from './user.types';

import {
    signInSuccess,
    signInFailure,
    signOutSuccess,
    signOutFailure,
    updateCurrentUserSuccess,
    updateCurrentUserFailure,
    getOverviewStatsSuccess,
    updateCurrentUserPasswordFailure,
    updateCurrentUserPasswordSuccess,
} from './user.actions';

import { setAlert, setLoading } from '../app/app.actions';

import { getRecentProgressReportsStart } from '../progress-report/progress-report.actions';
import { getRecentClientsStart } from '../client/client.actions';
import { getRecentTutorsStart } from '../tutor/tutor.actions';

export function* signInWithEmail({ payload }) {
    const { email, password } = payload;

    try {
        const response = yield axios.post('/api/v1/tutors/login', {
            email,
            password,
        });
        yield put(setAlert({ message: 'Sign in successful', type: 'success' }));
        yield put(signInSuccess({...response.data.data.user }));
    } catch (err) {
        yield put(setAlert({ message: err.response.data.message, type: 'error' }));
        yield put(signInFailure());
    }
}

export function* checkUserSession() {
    try {
        const response = yield axios.get('/api/v1/tutors/check-session');
        yield put(signInSuccess({...response.data.data.user }));
        yield put(setLoading());
    } catch (err) {
        yield put(setLoading());
    }
}

export function* signOut(role) {
    try {
        yield axios.get(
            `api/v1/${role === 'client' ? 'clients' : 'tutors'}/logout`
        );
        yield put(signOutSuccess());
        yield put(setLoading());
        yield put(setAlert({ message: 'Sign out successful', type: 'success' }));
    } catch (err) {
        yield put(setAlert({ message: err.response.data.message, type: 'error' }));
        yield put(signOutFailure());
    }
}

export function* updateUserDetails({ payload }) {
    const { role, obj } = payload;

    try {
        const response = yield axios.patch(
            `/api/v1/${role === 'client' ? 'clients' : 'tutors'}/update-my-data`,
            obj
        );
        yield put(updateCurrentUserSuccess(response.data.data.data));
        yield put(
            setAlert({ message: 'Your changes have been saved', type: 'success' })
        );
    } catch (err) {
        yield put(setAlert({ message: err.response.data.message, type: 'error' }));
        yield put(updateCurrentUserFailure());
    }
}

export function* getOverviewStats() {
    yield put(getRecentClientsStart());
    yield put(getRecentTutorsStart());
    yield put(getRecentProgressReportsStart());

    yield put(getOverviewStatsSuccess());
}

export function* updateUserPassword({ payload }) {
    const { role, obj } = payload;

    try {
        const res = yield axios.post(
            `/api/v1/${role === 'client' ? 'clients' : 'tutors'}/update-password`,
            obj
        );

        yield put(updateCurrentUserPasswordSuccess(res.data.data.user));
        yield put(
            setAlert({
                message: 'Your password has been changed successfully!!',
                type: 'error',
            })
        );
    } catch (err) {
        yield put(updateCurrentUserPasswordFailure());
        yield put(setAlert({ message: err.response.data.message, type: 'error' }));
    }
}

export function* onEmailSignInStart() {
    yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail);
}

export function* onCheckUserSessionStart() {
    yield takeLatest(UserActionTypes.CHECK_USER_SESSION, checkUserSession);
}

export function* onUserSignOutStart() {
    yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}

export function* onUpdateCurrentUserStart() {
    yield takeLatest(
        UserActionTypes.UPDATE_CURRENT_USER_START,
        updateUserDetails
    );
}

export function* onGetOverviewStatsStart() {
    yield takeLatest(UserActionTypes.GET_OVERVIEW_STATS_START, getOverviewStats);
}

export function* onUpdateCurrentUserPasswordStart() {
    yield takeLatest(
        UserActionTypes.UPDATE_CURRENT_USER_PASSWORD_START,
        updateUserPassword
    );
}

export function* userSagas() {
    yield all([
        call(onEmailSignInStart),
        call(onCheckUserSessionStart),
        call(onUserSignOutStart),
        call(onUpdateCurrentUserStart),
        call(onGetOverviewStatsStart),
        call(onUpdateCurrentUserPasswordStart),
    ]);
}