export const sortChecklists = (array) => {
    const timeline = {};

    array.forEach((el) => {
        const date = new Date(el.startDate);
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();


        //Add year to timeline if it isn't already there (the array of arrays is added this way to prevent JS from thinking the arrays are the same)
        if (!Object.keys(timeline).includes(`${year}`))
            timeline[year] = [
                [{}],
                [{}],
                [{}],
                [{}],
                [{}],
                [{}],
                [{}],
                [{}],
                [{}],
                [{}],
                [{}],
                [{}],
            ];

        if (typeof timeline[year][11 - month][0][day] === 'undefined') timeline[year][11 - month][0][day] = [];


        timeline[year][11 - month][0][day].push(el);
    });

    return timeline;
};