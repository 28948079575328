import WeeklyChecklistActionTypes from './weekly-checklist.types';

export const sendChecklistStart = (checklist) => ({
    type: WeeklyChecklistActionTypes.SEND_W_CHECKLIST_START,
    payload: checklist,
});

export const sendChecklistSuccess = () => ({
    type: WeeklyChecklistActionTypes.SEND_W_CHECKLIST_SUCCESS,
});

export const sendChecklistFailure = () => ({
    type: WeeklyChecklistActionTypes.SEND_W_CHECKLIST_FAILURE,
});

export const getChecklistsStart = () => ({
    type: WeeklyChecklistActionTypes.GET_W_CHECKLISTS_START,
});

export const getChecklistsSuccess = (checklists) => ({
    type: WeeklyChecklistActionTypes.GET_W_CHECKLISTS_SUCCESS,
    payload: checklists,
});

export const getChecklistsFailure = () => ({
    type: WeeklyChecklistActionTypes.GET_W_CHECKLISTS_FAILURE,
});

export const sortChecklists = (checklists) => ({
    type: WeeklyChecklistActionTypes.SORT_W_CHECKLISTS,
    payload: checklists,
});