import styled from 'styled-components';

export const Svg = styled.svg`
  width: 1.5rem;
  height: 1.5rem;
  fill: #fff;
  ${(props) => (props.black ? 'fill: #040404;' : '')}
  ${(props) => (props.medium ? 'height: 2rem; width: 2rem;' : '')}
    ${(
    props
  ) => (props.big ? 'height: 2.5rem; width: 2.5rem;' : '')}
    ${(props) =>
    props.bigger ? 'height: 3rem; width:3rem;' : ''}
    ${(props) =>
    props.active ? 'fill:#fcec04' : ''}
    ${(props) =>
    props.image ? 'width:5rem;height:5rem;' : ''}
`;
