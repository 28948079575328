const TutorActionTypes = {
    GET_RECENT_TUTORS_START: 'GET_RECENT_TUTORS_START',
    GET_RECENT_TUTORS_SUCCESS: 'GET_RECENT_TUTORS_SUCCESS',
    GET_RECENT_TUTORS_FAILURE: 'GET_RECENT_TUTORS_FAILURE',
    ADD_TUTOR_START: 'ADD_TUTOR_START',
    ADD_TUTOR_SUCCESS: 'ADD_TUTOR_SUCCESS',
    ADD_TUTOR_FAILURE: 'ADD_TUTOR_FAILURE',
    SET_ADDED_TO_FALSE: 'SET_ADDED_TO_FALSE',
    UPDATE_TUTOR_START: 'UPDATE_TUTOR_START',
    UPDATE_TUTOR_SUCCESS: 'UPDATE_TUTOR_SUCCESS',
    UPDATE_TUTOR_FAILURE: 'UPDATE_TUTOR_FAILURE',
    SET_UPDATED_TO_FALSE: 'SET_UPDATED_TO_FALSE',
    SEARCH_TUTORS_START: 'SEARCH_TUTORS_START',
    SEARCH_TUTORS_SUCCESS: 'SEARCH_TUTORS_SUCCESS',
    SEARCH_TUTORS_FAILURE: 'SEARCH_TUTORS_FAILURE',
    CLEAR_SEARCH_TUTORS: 'CLEAR_SEARCH_TUTORS',
    LOAD_MORE_TUTORS_START: 'LOAD_MORE_TUTORS_START',
    LOAD_MORE_TUTORS_SUCCESS: 'LOAD_MORE_TUTORS_SUCCESS',
    LOAD_MORE_TUTORS_FAILURE: 'LOAD_MORE_TUTORS_FAILURE',
    LOAD_LESS_TUTORS: 'LOAD_LESS_TUTORS',
    SEND_RESET_PASSWORD_START: 'SEND_RESET_PASSWORD_START',
    SEND_RESET_PASSWORD_SUCCESS: 'SEND_RESET_PASSWORD_SUCCESS',
    SEND_RESET_PASSWORD_FAILURE: 'SEND_RESET_PASSWORD_FAILURE',
    SET_SENDING_RESET_PASSWORD_OBJECT: 'SET_SENDING_RESET_PASSWORD_OBJECT',
};

export default TutorActionTypes;