import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  OverviewUserDetails,
  OverviewSubtitle,
  OverviewUserDetailsCard,
  OverviewUserForm,
  OverviewUserFormGroup,
  OverviewUserFormInputContainer,
  OverviewUserFormInput,
  OverviewUserFormLabel,
} from './profile.styles';

import { updateCurrentUserStart } from '../../Redux/user/user.actions';
import { setAlert } from '../../Redux/app/app.actions';

import Icon from '../icon/icon.component';
import CustomButton from '../custom-button/custom-button.component';
import ScrollToTopOnMount from '../../scrollToTopOnMount';

import { selectCurrentUser } from '../../Redux/user/user.selectors';

const Profile = () => {
  const currentUser = useSelector(selectCurrentUser);

  const dispatch = useDispatch();

  //Destructure necessary fields from currentUser
  const { name, email, phoneNumber, fields, location } = currentUser;

  //Initialize necessary state
  const [readOnly, setReadOnly] = useState(true);
  const [userDetails, setUserDetails] = useState({
    name,
    email,
    phoneNumber,
    location,
    fields: fields.join(', '),
  });

  //Handle input change
  const handleUserDetailsChange = (event) => {
    const { name, value } = event.target;

    setUserDetails((prevValues) => ({ ...prevValues, [name]: value }));
  };

  //Handle form submission
  const updateUserDetails = () => {
    //Define parameters to check
    const options = ['name', 'email', 'phoneNumber', 'location', 'fields'];
    //Create a new object with userDetails so as to avoid active mutating of state
    const userDetailsToSubmit = { ...userDetails };
    //Change fields value to an array
    userDetailsToSubmit.fields = [...userDetailsToSubmit.fields.split(', ')];

    //Loop through options and remove a field if its value has not been changed
    options.forEach((option) => {
      if (userDetailsToSubmit[option] === currentUser[option]) {
        delete userDetailsToSubmit[option];
      } else if (
        option === 'fields' &&
        userDetailsToSubmit[option].join(', ') ===
          currentUser[option].join(', ')
      ) {
        delete userDetailsToSubmit[option];
      }
    });

    //Prevent the form from submitting if no change has been made
    if (!Object.keys(userDetailsToSubmit).length)
      return dispatch(
        setAlert({ message: 'No data was changed', type: 'error' })
      );

    dispatch(
      updateCurrentUserStart({
        role: currentUser.role,
        obj: userDetailsToSubmit,
      })
    );

    setReadOnly(true);
  };
  return (
    <>
      <ScrollToTopOnMount />
      <OverviewUserDetails>
        <OverviewSubtitle>Your Details</OverviewSubtitle>
        <OverviewUserDetailsCard readOnly={readOnly}>
          <OverviewUserForm onSubmit={updateUserDetails}>
            <OverviewUserFormGroup>
              <OverviewUserFormInputContainer>
                <OverviewUserFormInput
                  type="text"
                  name="name"
                  placeholder=" "
                  readOnly={readOnly}
                  value={userDetails.name}
                  onChange={handleUserDetailsChange}
                />
                <OverviewUserFormLabel htmlFor="name">
                  Name
                </OverviewUserFormLabel>
              </OverviewUserFormInputContainer>

              <OverviewUserFormInputContainer>
                <OverviewUserFormInput
                  type="email"
                  name="email"
                  placeholder=" "
                  readOnly={readOnly}
                  value={userDetails.email}
                  onChange={handleUserDetailsChange}
                />
                <OverviewUserFormLabel htmlFor="email">
                  Email
                </OverviewUserFormLabel>
              </OverviewUserFormInputContainer>

              <OverviewUserFormInputContainer>
                <OverviewUserFormInput
                  type="number"
                  name="phoneNumber"
                  placeholder=" "
                  readOnly={readOnly}
                  value={userDetails.phoneNumber}
                  onChange={handleUserDetailsChange}
                />
                <OverviewUserFormLabel htmlFor="phoneNumber">
                  Phone Number
                </OverviewUserFormLabel>
              </OverviewUserFormInputContainer>
            </OverviewUserFormGroup>

            <OverviewUserFormGroup>
              <OverviewUserFormInputContainer halfWidth>
                <OverviewUserFormInput
                  location
                  type="text"
                  name="location"
                  placeholder=" "
                  readOnly={readOnly}
                  value={userDetails.location}
                  onChange={handleUserDetailsChange}
                />
                <OverviewUserFormLabel htmlFor="address">
                  Location
                </OverviewUserFormLabel>
              </OverviewUserFormInputContainer>

              <OverviewUserFormInputContainer halfWidth>
                <OverviewUserFormInput
                  type="text"
                  name="fields"
                  placeholder=" "
                  readOnly={readOnly}
                  value={userDetails.fields}
                  onChange={handleUserDetailsChange}
                />
                <OverviewUserFormLabel htmlFor="fields">
                  Fields
                </OverviewUserFormLabel>
              </OverviewUserFormInputContainer>
            </OverviewUserFormGroup>

            <OverviewUserFormGroup row justifyFlexStart>
              {readOnly ? (
                <CustomButton
                  type="button"
                  smallBorder
                  onClick={() => setReadOnly(false)}
                >
                  <Icon iconName="create" black medium />
                  Edit
                </CustomButton>
              ) : (
                <>
                  <CustomButton
                    type="button"
                    smallBorder
                    onClick={updateUserDetails}
                  >
                    <Icon iconName="checkmark" black medium />
                    Update
                  </CustomButton>
                  <CustomButton
                    type="button"
                    smallBorder
                    onClick={() => {
                      setReadOnly(true);
                      setUserDetails({
                        name,
                        email,
                        phoneNumber,
                        fields: fields.join(', '),
                        location,
                      });
                    }}
                  >
                    <Icon iconName="cancel" black medium />
                    Cancel
                  </CustomButton>
                </>
              )}
            </OverviewUserFormGroup>
          </OverviewUserForm>
        </OverviewUserDetailsCard>
      </OverviewUserDetails>
    </>
  );
};

export default Profile;
