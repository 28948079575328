import styled, { css } from 'styled-components';

const borderWidth = '1.5px';

const ButtonSmall = css`
  padding: calc(1rem - ${borderWidth});
  font-size: 1.2rem;
  border-radius: 0.5rem;
`;

const smallBorder = css`
  border-radius: 5px;
  padding: calc(1rem - ${borderWidth}) calc(4rem - ${borderWidth});
  box-shadow: 0 1.5px 3px rgba(4, 4, 4, 0.5);
  &:active {
    box-shadow: 0 0.8px 1.6px rgba(4, 4, 4, 0.5);
  }
`;

const smallerBorder = css`
  border-radius: 5px;
  padding: calc(1rem - ${borderWidth}) calc(2rem - ${borderWidth});
  box-shadow: 0 1.5px 3px rgba(4, 4, 4, 0.5);
  &:active {
    box-shadow: 0 0.8px 1.6px rgba(4, 4, 4, 0.5);
  }
`;

const whiteBg = css`
  background-color: #fff;
  &:focus,
  &:hover {
    background-color: #fff;
    border-color: #040404;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${borderWidth} solid transparent;
  padding: calc(1.5rem - ${borderWidth}) calc(5rem - ${borderWidth});
  border-radius: 5px;
  background-color: #fcec04;
  text-transform: uppercase;
  font-size: 1.5rem;
  box-shadow: 0 0.3rem 0.6rem rgba(4, 4, 4, 0.5);
  white-space: nowrap;
  outline: none;
  cursor: pointer;
  transition-property: border-color, box-shadow;
  transition-duration: 0.2s;
  & > svg {
    margin-right: 1rem;
  }
  &:active {
    box-shadow: 0 0.3rem 0.5rem rgba(4, 4, 4, 0.5);
  }
  &:focus,
  &:hover {
    border-color: #024a92;
    box-shadow: 0 0.4rem 0.8rem rgba(4, 4, 4, 0.5);
  }
  &:disabled {
    cursor: not-allowed;
  }
  ${(props) => (props.small ? ButtonSmall : '')}
  ${(props) => (props.flexStart ? 'align-self: flex-start;' : '')}
  ${(props) => (props.smallBorder ? smallBorder : '')}
    ${(props) => (props.smallerBorder ? smallerBorder : '')}
    ${({ whiteBG }) => (whiteBG ? whiteBg : '')}
`;
