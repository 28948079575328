import { all, call } from 'redux-saga/effects';

import { userSagas } from './user/user.sagas';
import { progressReportSagas } from './progress-report/progress-report.sagas';
import { tutorSagas } from './tutor/tutor.sagas';
import { clientSagas } from './client/client.sagas';
import { weeklyChecklistSagas } from './weekly-checklist/weekly-checklist.sagas';
import { engagementSagas } from './engagements/engagements.sagas';

export default function* rootSaga() {
    yield all([
        call(userSagas),
        call(progressReportSagas),
        call(tutorSagas),
        call(clientSagas),
        call(weeklyChecklistSagas),
        call(engagementSagas),
    ]);
}