const ProgressReportActionTypes = {
    SEND_REPORT_START: 'SEND_REPORT_START',
    SEND_REPORT_SUCCESS: 'SEND_REPORT_SUCCESS',
    SEND_REPORT_FAILURE: 'SEND_REPORT_FAILURE',
    UPDATE_REPORT_START: 'UPDATE_REPORT_START',
    UPDATE_REPORT_SUCCESS: 'UPDATE_REPORT_SUCCESS',
    UPDATE_REPORT_FAILURE: 'UPDATE_REPORT_FAILURE',
    SET_REPORT_TO_EDIT: 'SET_REPORT_TO_EDIT',
    APPROVE_REPORT_START: 'APPROVE_REPORT_START',
    APPROVE_REPORT_SUCCESS: 'APPROVE_REPORT_SUCCESS',
    APPROVE_REPORT_FAILURE: 'APPROVE_REPORT_FAILURE',
    COMMENT_ON_REPORT_START: 'COMMENT_ON_REPORT_START',
    COMMENT_ON_REPORT_SUCCESS: 'COMMENT_ON_REPORT_SUCCESS',
    COMMENT_ON_REPORT_FAILURE: 'COMMENT_ON_REPORT_FAILURE',
    GET_PROGRESS_REPORTS_START: 'GET_PROGRESS_REPORTS_START',
    GET_PROGRESS_REPORTS_SUCCESS: 'GET_PROGRESS_REPORTS_SUCCESS',
    GET_PROGRESS_REPORTS_FAILURE: 'GET_PROGRESS_REPORTS_FAILURE',
    CLEAR_REPORTS: 'CLEAR_REPORTS',
    SORT_REPORTS: 'SORT_REPORTS',
    LOAD_MORE_REPORTS_START: 'LOAD_MORE_REPORTS_START',
    LOAD_MORE_REPORTS_SUCCESS: 'LOAD_MORE_REPORTS_SUCCESS',
    LOAD_MORE_REPORTS_FAILURE: 'LOAD_MORE_REPORTS_FAILURE',
    LOAD_LESS_REPORTS: 'LOAD_LESS_REPORTS',
    GET_RECENT_PROGRESS_REPORTS_START: 'GET_RECENT_PROGRESS_REPORTS_START',
    GET_RECENT_PROGRESS_REPORTS_SUCCESS: 'GET_RECENT_PROGRESS_REPORTS_SUCCESS',
    GET_RECENT_PROGRESS_REPORTS_FAILURE: 'GET_RECENT_PROGRESS_REPORTS_FAILURE',
    GET_PREV_CURRENT_REPORTS_COUNT_START: 'GET_PREV_CURRENT_REPORTS_COUNT_START',
    GET_PREV_CURRENT_REPORTS_COUNT_SUCCESS: 'GET_PREV_CURRENT_REPORTS_COUNT_SUCCESS',
    GET_PREV_CURRENT_REPORTS_COUNT_FAILURE: 'GET_PREV_CURRENT_REPORTS_COUNT_FAILURE',
};

export default ProgressReportActionTypes;