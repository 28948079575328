import UserActionTypes from './user.types';

const INITIAL_STATE = {
    currentUser: null,
    emailSignInLoading: false,
    gottenOverviewStats: false,
    gettingOverviewStats: false,
    updatingPassword: false,
    updatedPassword: false,
};

const userReducer = (currentState = INITIAL_STATE, action) => {
    switch (action.type) {
        case UserActionTypes.EMAIL_SIGN_IN_START:
            return {
                ...currentState,
                emailSignInLoading: true,
            };
        case UserActionTypes.GET_OVERVIEW_STATS_START:
            return {
                ...currentState,
                gettingOverviewStats: true,
            };
        case UserActionTypes.UPDATE_CURRENT_USER_PASSWORD_START:
            return {
                ...currentState,
                updatingPassword: true,
            };
        case UserActionTypes.SIGN_IN_SUCCESS:
        case UserActionTypes.SET_CURRENT_USER:
        case UserActionTypes.UPDATE_CURRENT_USER_SUCCESS:
            return {
                ...currentState,
                currentUser: action.payload,
                emailSignInLoading: false,
            };
        case UserActionTypes.UPDATE_CURRENT_USER_PASSWORD_SUCCESS:
            return {
                ...currentState,
                currentUser: {...action.payload },
                updatingPassword: false,
                updatedPassword: true,
            };
        case UserActionTypes.GET_OVERVIEW_STATS_SUCCESS:
            return {
                ...currentState,
                gettingOverviewStats: false,
                gottenOverviewStats: true,
            };
        case UserActionTypes.SIGN_OUT_SUCCESS:
            return {
                ...currentState,
                currentUser: null,
            };
        case UserActionTypes.SIGN_IN_FAILURE:
            return {
                ...currentState,
                currentUser: null,
                emailSignInLoading: false,
            };
        case UserActionTypes.SIGN_OUT_FAILURE:
            return {
                ...currentState,
            };
        case UserActionTypes.GET_OVERVIEW_STATS_FAILURE:
            return {
                ...currentState,
                gettingOverviewStats: false,
                gottenOverviewStats: false,
            };
        case UserActionTypes.UPDATE_CURRENT_USER_PASSWORD_FAILURE:
            return {
                ...currentState,
                updatingPassword: false,
                updatedPassword: false,
            };
        default:
            return currentState;
    }
};

export default userReducer;