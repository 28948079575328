import { FormInputContainer, Input, Label } from './input-container.styles';

import Icon from '../icon/icon.component';

const FormInput = ({ name, icon, label, ...otherProps }) => {
  return (
    <FormInputContainer>
      <Input name={name} {...otherProps} />
      <Label htmlFor={name}>
        {icon ? <Icon iconName={icon} /> : ''}
        <span>{label}</span>
      </Label>
    </FormInputContainer>
  );
};

export default FormInput;
