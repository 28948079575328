import ProgressReportTypes from './progress-report.types.js';

export const sendProgressReportStart = ({ report, role }) => ({
    type: ProgressReportTypes.SEND_REPORT_START,
    payload: { report, role },
});

export const sendProgressReportSuccess = ({ report, role }) => ({
    type: ProgressReportTypes.SEND_REPORT_SUCCESS,
    payload: { report, role },
});

export const sendProgressReportFailure = () => ({
    type: ProgressReportTypes.SEND_REPORT_FAILURE,
});
export const updateProgressReportStart = ({ reportObj, id }) => ({
    type: ProgressReportTypes.UPDATE_REPORT_START,
    payload: { reportObj, id },
});

export const updateProgressReportSuccess = (newReport) => ({
    type: ProgressReportTypes.UPDATE_REPORT_SUCCESS,
    payload: newReport,
});

export const updateProgressReportFailure = () => ({
    type: ProgressReportTypes.UPDATE_REPORT_FAILURE,
});

export const setReportToEdit = (report) => ({
    type: ProgressReportTypes.SET_REPORT_TO_EDIT,
    payload: report,
});

export const approveReportStart = (id) => ({
    type: ProgressReportTypes.APPROVE_REPORT_START,
    payload: id,
});

export const approveReportSuccess = (id) => ({
    type: ProgressReportTypes.APPROVE_REPORT_SUCCESS,
    payload: id,
});

export const approveReportFailure = (id) => ({
    type: ProgressReportTypes.APPROVE_REPORT_FAILURE,
    payload: id,
});

export const commentOnReportStart = ({ id, comment }) => ({
    type: ProgressReportTypes.COMMENT_ON_REPORT_START,
    payload: { id, comment },
});

export const commentOnReportSuccess = (id) => ({
    type: ProgressReportTypes.COMMENT_ON_REPORT_SUCCESS,
    payload: id,
});

export const commentOnReportFailure = (id) => ({
    type: ProgressReportTypes.COMMENT_ON_REPORT_FAILURE,
    payload: id,
});

export const getProgressReportsStart = ({ search }) => ({
    type: ProgressReportTypes.GET_PROGRESS_REPORTS_START,
    payload: { search },
});

export const getProgressReportsSuccess = (reports) => ({
    type: ProgressReportTypes.GET_PROGRESS_REPORTS_SUCCESS,
    payload: reports,
});

export const getProgressReportsFailure = () => ({
    type: ProgressReportTypes.GET_PROGRESS_REPORTS_FAILURE,
});

export const clearReports = () => ({
    type: ProgressReportTypes.CLEAR_REPORTS,
});

export const sortReports = ({ reports, role }) => ({
    type: ProgressReportTypes.SORT_REPORTS,
    payload: { reports, role },
});

export const loadMoreReportsStart = ({ role, pageNo, search }) => ({
    type: ProgressReportTypes.LOAD_MORE_REPORTS_START,
    payload: { role, pageNo, search },
});

export const loadMoreReportsSuccess = (response) => ({
    type: ProgressReportTypes.LOAD_MORE_REPORTS_SUCCESS,
    payload: response,
});

export const loadMoreReportsFailure = (pageNo) => ({
    type: ProgressReportTypes.LOAD_MORE_REPORTS_FAILURE,
    payload: pageNo,
});

export const loadLessReports = () => ({
    type: ProgressReportTypes.LOAD_LESS_REPORTS,
});

export const getRecentProgressReportsStart = () => ({
    type: ProgressReportTypes.GET_RECENT_PROGRESS_REPORTS_START,
});

export const getRecentProgressReportsSuccess = (response) => ({
    type: ProgressReportTypes.GET_RECENT_PROGRESS_REPORTS_SUCCESS,
    payload: response,
});

export const getRecentProgressReportsFailure = () => ({
    type: ProgressReportTypes.GET_RECENT_PROGRESS_REPORTS_FAILURE,
});

export const getPrevCurrentReportsCountStart = () => ({
    type: ProgressReportTypes.GET_PREV_CURRENT_REPORTS_COUNT_START,
});

export const getPrevCurrentReportsCountSuccess = (data) => ({
    type: ProgressReportTypes.GET_PREV_CURRENT_REPORTS_COUNT_SUCCESS,
    payload: data,
});

export const getPrevCurrentReportsCountFailure = () => ({
    type: ProgressReportTypes.GET_PREV_CURRENT_REPORTS_COUNT_FAILURE,
});