import { Svg } from './icon.styles';

const Icon = ({ iconName, ...otherProps }) => {
  return (
    <Svg {...otherProps}>
      <use xlinkHref={`/img/sprite.svg#icon-${iconName}`}></use>
    </Svg>
  );
};

export default Icon;
