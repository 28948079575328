import ClientActionTypes from './client.types';

const INITIAL_STATE = {
    totalClients: 0,
    totalActive: 0,
    recentClients: [],
    gettingRecentClients: true,
    gottenRecentClients: false,
    addingClient: false,
    addedClient: false,
    updatingClient: false,
    updatedClient: false,
    searchingClients: false,
    searchedClients: false,
    searchObject: {},
    searchResults: [],
    totalFound: 0,
    totalShown: 0,
    loadingMoreClients: false,
    currentPage: 1,
};

const tutorReducer = (currentState = INITIAL_STATE, action) => {
    switch (action.type) {
        case ClientActionTypes.ADD_CLIENT_START:
            return {
                ...currentState,
                addingClient: true,
                addedClient: false,
            };
        case ClientActionTypes.UPDATE_CLIENT_START:
            return {
                ...currentState,
                updatingClient: true,
                updatedClient: false,
            };
        case ClientActionTypes.SEARCH_CLIENTS_START:
            return {
                ...currentState,
                searchingClients: true,
                searchedClients: false,
                searchObject: {...action.payload },
                currentPage: 1,
            };
        case ClientActionTypes.LOAD_MORE_CLIENTS_START:
            return {
                ...currentState,
                loadingMoreClients: true,
                currentPage: action.payload.page,
            };
        case ClientActionTypes.ADD_CLIENT_SUCCESS:
            return {
                ...currentState,
                addingClient: false,
                addedClient: true,
                recentClients: [
                    action.payload,
                    ...currentState.recentClients.filter((el, i) => i < 9),
                ],
                totalClients: currentState.totalClients + 1,
                totalActive: action.payload.engaged ?
                    currentState.totalActive + 1 :
                    currentState.totalActive,
            };
        case ClientActionTypes.UPDATE_CLIENT_SUCCESS:
            return {
                ...currentState,
                recentClients: [
                    ...currentState.recentClients.map((el) =>
                        el._id === action.payload.data._id ?
                        {...action.payload.data } :
                        {...el }
                    ),
                ],
                searchResults: [
                    ...currentState.searchResults.map((el) =>
                        el._id === action.payload.data._id ?
                        {...action.payload.data } :
                        {...el }
                    ),
                ],
                totalActive: action.payload.totalActive,
                updatingClient: false,
                updatedClient: true,
            };
        case ClientActionTypes.GET_RECENT_CLIENTS_SUCCESS:
            return {
                ...currentState,
                totalClients: action.payload.totalClients,
                totalActive: action.payload.totalActive,
                recentClients: [...action.payload.data],
                gettingRecentClients: false,
                gottenRecentClients: true,
            };
        case ClientActionTypes.SEARCH_CLIENTS_SUCCESS:
            return {
                ...currentState,
                searchingClients: false,
                searchedClients: true,
                searchResults: [...action.payload.data.data],
                totalFound: action.payload.totalDocuments,
                totalShown: action.payload.results,
            };
        case ClientActionTypes.LOAD_MORE_CLIENTS_SUCCESS:
            return {
                ...currentState,
                loadingMoreClients: false,
                searchResults: [
                    ...currentState.searchResults,
                    ...action.payload.data.data,
                ],
                totalShown: currentState.totalShown + action.payload.results,
            };
        case ClientActionTypes.SET_ADDED_TO_FALSE:
            return {
                ...currentState,
                addedClient: false,
            };
        case ClientActionTypes.SET_UPDATED_TO_FALSE:
            return {
                ...currentState,
                updatedClient: false,
            };
        case ClientActionTypes.CLEAR_SEARCH_CLIENTS:
            return {
                ...currentState,
                searchObject: {},
                searchedClients: false,
                searchResults: [],
                currentPage: 1,
                totalFound: 0,
                totalShown: 0,
            };
        case ClientActionTypes.LOAD_LESS_CLIENTS:
            const filtered = currentState.searchResults.filter((el, i) => i < 10);
            return {
                ...currentState,
                searchResults: [...filtered],
                totalShown: filtered.length,
                currentPage: 1,
            };
        case ClientActionTypes.GET_RECENT_CLIENTS_FAILURE:
            return {
                ...currentState,
                totalClients: 0,
                totalActive: 0,
                recentClients: [],
                gettingRecentClients: false,
                gottenRecentClients: false,
            };
        case ClientActionTypes.ADD_CLIENT_FAILURE:
            return {
                ...currentState,
                addingClient: false,
                addedClient: false,
                recentClients: [...currentState.recentClients],
            };
        case ClientActionTypes.UPDATE_CLIENT_FAILURE:
            return {
                ...currentState,
                updatingClient: false,
                updatedClient: false,
                recentClients: [...currentState.recentClients],
                totalActive: currentState.totalActive,
            };
        case ClientActionTypes.SEARCH_CLIENTS_FAILURE:
            return {
                ...currentState,
                searchingClients: false,
                searchedClients: false,
                searchResults: [...currentState.searchResults],
            };
        case ClientActionTypes.LOAD_MORE_CLIENTS_FAILURE:
            return {
                ...currentState,
                loadingMoreClients: false,
                searchResults: [...currentState.searchResults],
                totalShown: currentState.totalShown,
                currentPage: action.payload,
            };

        default:
            return currentState;
    }
};

export default tutorReducer;