import AppActionTypes from './app.types';

export const setLoading = () => ({
    type: AppActionTypes.SET_LOADING_TO_FALSE,
});

export const setAlert = ({ message, type }) => ({
    type: AppActionTypes.SET_ALERT,
    payload: {
        message,
        type,
    },
});

export const removeAlert = () => ({
    type: AppActionTypes.REMOVE_ALERT,
});

export const dashboardHamburgerClicked = (val) => ({
    type: AppActionTypes.DASHBOARD_HAMBURGER_CLICKED,
    payload: val,
});