import { put, all, call, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import EngagementActionTypes from './engagements.types';

import {
    getRecentEngagementsSuccess,
    getRecentEngagementsFailure,
    addEngagementSuccess,
    addEngagementFailure,
    updateEngagementSuccess,
    updateEngagementFailure,
    searchEngagementsSuccess,
    searchEngagementsFailure,
    loadMoreEngagementsFailure,
    loadMoreEngagementsSuccess,
} from './engagements.actions';

import { setAlert } from '../app/app.actions';

export function* getRecentEngagements() {
    try {
        const results = yield Promise.all([
            axios.get('/api/v1/engagements?sort=-startDate'),
            axios.get('/api/v1/engagements?status=ongoing&fields=_id'),
            axios.get('/api/v1/engagements?status=suspended&fields=_id'),
            axios.get('/api/v1/engagements?status=completed&fields=_id'),
        ]);

        yield put(
            getRecentEngagementsSuccess({
                engagements: results[0].data.data.data,
                totalEngagements: results[0].data.totalDocuments,
                totalOngoingEngagements: results[1].data.totalDocuments,
                totalSuspendedEngagements: results[2].data.totalDocuments,
                totalCompletedEngagements: results[3].data.totalDocuments,
            })
        );
    } catch (err) {
        yield put(setAlert({ message: err.response.data.message, type: 'error' }));
        yield put(getRecentEngagementsFailure());
    }
}

export function* addEngagement({ payload }) {
    try {
        const res = yield axios.post('/api/v1/engagements', payload);

        yield put(
            setAlert({
                message: 'Engagement added successfully!! 🥳🥳',
                type: 'success',
            })
        );

        yield put(addEngagementSuccess({...res.data.data.data }));
    } catch (err) {
        yield put(setAlert({ message: err.response.data.message, type: 'error' }));

        yield put(addEngagementFailure());
    }
}

export function* updateEngagement({ payload }) {
    try {
        const res = yield axios.patch(
            `/api/v1/engagements/${payload._id}`,
            payload
        );

        yield put(
            setAlert({
                message: 'Engagement updated successfully!! 🥳🥳',
                type: 'success',
            })
        );

        yield put(updateEngagementSuccess(res.data.data.data));
    } catch (err) {
        yield put(setAlert({ message: err.response.data.message, type: 'error' }));
        yield put(updateEngagementFailure());
    }
}

export function* searchEngagements({ payload }) {
    const { searchBy, searchTerm } = payload;

    let query;

    switch (searchBy) {
        case 'tutor':
            query = `tutorName[regex]=${searchTerm}&tutorName[options]=i&sort=-startDate`;
            break;

        case 'client':
            query = `wardSurname[regex]=${searchTerm}&wardSurname[options]=i&sort=-startDate`;
            break;

        case 'wardName':
            const [familyName, wardName] = searchTerm.split(' ');

            query = `wardSurname[regex]=${familyName}&wardSurname[options]=i&wardName[regex]=${wardName}&wardName[options]=i&sort=-startDate`;
            break;

        case 'classType':
            query = `days__classType=${searchTerm.toLowerCase()}&sort=-startDate`;
            break;

        case 'dateRange':
            const { fromDate, toDate } = searchTerm;

            query = `startDate[gte]=${fromDate}&endDate[lte]=${toDate}&sort=-startDate`;
            break;

        case 'weekday':
            query = `days__day=${searchTerm.toLowerCase()}&sort=-startDate`;
            break;

        case 'subject':
            query = `in[subjects]=${searchTerm.toLowerCase()}&sort=-startDate`;
            break;

        case 'status':
            query = `status=${searchTerm.toLowerCase()}&sort=-startDate`;
            break;

        default:
            break;
    }

    try {
        const res = yield axios.get(`/api/v1/engagements?${query}`);

        yield put(searchEngagementsSuccess(res.data));
    } catch (err) {
        yield put(setAlert({ message: err.response.data.message, type: 'error' }));
        yield put(searchEngagementsFailure());
    }
}

export function* loadMoreEngagements({ payload }) {
    const { searchBy, searchTerm, page } = payload;

    let query;

    switch (searchBy) {
        case 'tutor':
            query = `tutorName[regex]=${searchTerm}&tutorName[options]=i&sort=-startDate`;
            break;

        case 'client':
            query = `wardSurname[regex]=${searchTerm}&wardSurname[options]=i&sort=-startDate`;
            break;

        case 'wardName':
            const [familyName, wardName] = searchTerm.split(' ');

            query = `wardSurname[regex]=${familyName}&wardSurname[options]=i&wardName[regex]=${wardName}&wardName[options]=i&sort=-startDate`;
            break;

        case 'classType':
            query = `days__classType=${searchTerm.toLowerCase()}&sort=-startDate`;
            break;

        case 'dateRange':
            const { fromDate, toDate } = searchTerm;

            query = `startDate[gte]=${fromDate}&endDate[lte]=${toDate}&sort=-startDate`;
            break;

        case 'weekday':
            query = `days__day=${searchTerm.toLowerCase()}&sort=-startDate`;
            break;

        case 'subject':
            query = `in[subjects]=${searchTerm.toLowerCase()}&sort=-startDate`;
            break;

        case 'status':
            query = `status=${searchTerm.toLowerCase()}&sort=-startDate`;
            break;

        default:
            break;
    }

    try {
        const res = yield axios.get(`/api/v1/engagements?${query}&page=${page}`);

        yield put(loadMoreEngagementsSuccess(res.data));
    } catch (err) {
        yield put(setAlert({ message: err.response.data.message, type: 'error' }));
        yield put(loadMoreEngagementsFailure(page - 1));
    }
}

export function* onGetRecentEngagementsStart() {
    yield takeLatest(
        EngagementActionTypes.GET_RECENT_ENGAGEMENTS_START,
        getRecentEngagements
    );
}

export function* onAddEngagementStart() {
    yield takeLatest(EngagementActionTypes.ADD_ENGAGEMENT_START, addEngagement);
}

export function* onUpdateEngagementStart() {
    yield takeLatest(
        EngagementActionTypes.UPDATE_ENGAGEMENT_START,
        updateEngagement
    );
}

export function* onSearchEngagementsStart() {
    yield takeLatest(
        EngagementActionTypes.SEARCH_ENGAGEMENTS_START,
        searchEngagements
    );
}

export function* onLoadMoreEngagementsStart() {
    yield takeLatest(
        EngagementActionTypes.LOAD_MORE_ENGAGEMENTS_START,
        loadMoreEngagements
    );
}

export function* engagementSagas() {
    yield all([
        call(onGetRecentEngagementsStart),
        call(onAddEngagementStart),
        call(onUpdateEngagementStart),
        call(onSearchEngagementsStart),
        call(onLoadMoreEngagementsStart),
    ]);
}