import TutorActionTypes from './tutor.types';

import { returnFalse } from './tutor.utils';

const INITIAL_STATE = {
    totalTutors: 0,
    totalActive: 0,
    recentTutors: [],
    gettingRecentTutors: true,
    gottenRecentTutors: false,
    addingTutor: false,
    addedTutor: false,
    updatingTutor: false,
    updatedTutor: false,
    searchingTutors: false,
    searchedTutors: false,
    searchObject: {},
    searchResults: [],
    totalFound: 0,
    totalShown: 0,
    loadingMoreTutors: false,
    currentPage: 1,
    sendingResetPassword: {},
};

const tutorReducer = (currentState = INITIAL_STATE, action) => {
    switch (action.type) {
        case TutorActionTypes.ADD_TUTOR_START:
            return {
                ...currentState,
                addingTutor: true,
                addedTutor: false,
            };
        case TutorActionTypes.UPDATE_TUTOR_START:
            return {
                ...currentState,
                updatingTutor: true,
                updatedTutor: false,
            };
        case TutorActionTypes.SEARCH_TUTORS_START:
            return {
                ...currentState,
                searchingTutors: true,
                searchedTutors: false,
                searchObject: {...action.payload },
                currentPage: 1,
            };
        case TutorActionTypes.LOAD_MORE_TUTORS_START:
            return {
                ...currentState,
                loadingMoreTutors: true,
                currentPage: action.payload.page,
            };
        case TutorActionTypes.SEND_RESET_PASSWORD_START:
            return {
                ...currentState,
                sendingResetPassword: {
                    ...currentState.sendingResetPassword,
                    [action.payload.id]: true,
                },
            };
        case TutorActionTypes.ADD_TUTOR_SUCCESS:
            return {
                ...currentState,
                addingTutor: false,
                addedTutor: true,
                recentTutors: [
                    action.payload,
                    ...currentState.recentTutors.filter((el, i) => i < 9),
                ],
                totalTutors: currentState.totalTutors + 1,
                totalActive: action.payload.engaged ?
                    currentState.totalActive + 1 :
                    currentState.totalActive,
            };
        case TutorActionTypes.UPDATE_TUTOR_SUCCESS:
            return {
                ...currentState,
                recentTutors: [
                    ...currentState.recentTutors.map((el) =>
                        el._id === action.payload.data._id ?
                        {...action.payload.data } :
                        {...el }
                    ),
                ],
                searchResults: [
                    ...currentState.searchResults.map((el) =>
                        el._id === action.payload.data._id ?
                        {...action.payload.data } :
                        {...el }
                    ),
                ],
                totalActive: action.payload.totalActive,
                updatingTutor: false,
                updatedTutor: true,
            };
        case TutorActionTypes.GET_RECENT_TUTORS_SUCCESS:
            return {
                ...currentState,
                totalTutors: action.payload.totalTutors,
                totalActive: action.payload.totalActive,
                recentTutors: [...action.payload.data],
                gettingRecentTutors: false,
                gottenRecentTutors: true,
            };
        case TutorActionTypes.SEARCH_TUTORS_SUCCESS:
            return {
                ...currentState,
                searchingTutors: false,
                searchedTutors: true,
                searchResults: [...action.payload.data.data],
                totalFound: action.payload.totalDocuments,
                totalShown: action.payload.results,
            };
        case TutorActionTypes.LOAD_MORE_TUTORS_SUCCESS:
            return {
                ...currentState,
                loadingMoreTutors: false,
                searchResults: [
                    ...currentState.searchResults,
                    ...action.payload.data.data,
                ],
                totalShown: currentState.totalShown + action.payload.results,
            };
        case TutorActionTypes.SEND_RESET_PASSWORD_SUCCESS:
            return {
                ...currentState,
                sendingResetPassword: {
                    ...currentState.sendingResetPassword,
                    [action.payload]: false,
                },
            };
        case TutorActionTypes.SET_ADDED_TO_FALSE:
            return {
                ...currentState,
                addedTutor: false,
            };
        case TutorActionTypes.SET_UPDATED_TO_FALSE:
            return {
                ...currentState,
                updatedTutor: false,
            };
        case TutorActionTypes.CLEAR_SEARCH_TUTORS:
            return {
                ...currentState,
                searchObject: {},
                searchedTutors: false,
                searchResults: [],
                currentPage: 1,
                totalFound: 0,
                totalShown: 0,
            };
        case TutorActionTypes.LOAD_LESS_TUTORS:
            const filtered = currentState.searchResults.filter((el, i) => i < 10);
            return {
                ...currentState,
                searchResults: [...filtered],
                totalShown: filtered.length,
                currentPage: 1,
            };
        case TutorActionTypes.SET_SENDING_RESET_PASSWORD_OBJECT:
            return {
                ...currentState,
                sendingResetPassword: {...returnFalse(action.payload) },
            };
        case TutorActionTypes.GET_RECENT_TUTORS_FAILURE:
            return {
                ...currentState,
                totalTutors: 0,
                totalActive: 0,
                recentTutors: [],
                gettingRecentTutors: false,
                gottenRecentTutors: false,
            };
        case TutorActionTypes.ADD_TUTOR_FAILURE:
            return {
                ...currentState,
                addingTutor: false,
                addedTutor: false,
                recentTutors: [...currentState.recentTutors],
            };
        case TutorActionTypes.UPDATE_TUTOR_FAILURE:
            return {
                ...currentState,
                updatingTutor: false,
                updatedTutor: false,
                recentTutors: [...currentState.recentTutors],
                totalActive: currentState.totalActive,
            };
        case TutorActionTypes.SEARCH_TUTORS_FAILURE:
            return {
                ...currentState,
                searchingTutors: false,
                searchedTutors: false,
                searchResults: [...currentState.searchResults],
            };
        case TutorActionTypes.LOAD_MORE_TUTORS_FAILURE:
            return {
                ...currentState,
                loadingMoreTutors: false,
                searchResults: [...currentState.searchResults],
                totalShown: currentState.totalShown,
                currentPage: action.payload,
            };
        case TutorActionTypes.SEND_RESET_PASSWORD_FAILURE:
            return {
                ...currentState,
                sendingResetPassword: {
                    ...currentState.sendingResetPassword,
                    [action.payload]: false,
                },
            };

        default:
            return currentState;
    }
};

export default tutorReducer;