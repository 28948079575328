// import uniqid from 'uniqid';

import AppActionTypes from './app.types';

const INITIAL_STATE = {
    alert: null,
    appLoading: true,
    dashboardHamburgerActive: false,
};

const appReducer = (currentState = INITIAL_STATE, action) => {
    switch (action.type) {
        case AppActionTypes.SET_ALERT:
            return {
                ...currentState,
                alert: {...action.payload },
            };
        case AppActionTypes.REMOVE_ALERT:
            return {
                ...currentState,
                alert: null,
            };
        case AppActionTypes.SET_LOADING_TO_FALSE:
            return {
                ...currentState,
                appLoading: false,
            };
        case AppActionTypes.DASHBOARD_HAMBURGER_CLICKED:
            return {
                ...currentState,
                dashboardHamburgerActive: !action.payload,
            };
        default:
            return currentState;
    }
};

export default appReducer;