import { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { createStructuredSelector } from 'reselect';

import {
  Sidebar,
  SidebarContainer,
  SidebarNavItemLink,
  SidebarNavItemDiv,
  SidebarNavItemText,
  NavDropdown,
  NavDropdownItem,
} from './dashboard-sidebar.styles';

import { selectCurrentUser } from '../../Redux/user/user.selectors';

import Icon from '../icon/icon.component';

const DashboardSidebar = ({ hamburgerActive, match, location, user }) => {
  //Initialize dropdown shown for the three dropdowns available
  const [dropdownShown, setDropdownShown] = useState([
    false,
    false,
    false,
    false,
  ]);

  //Parse query string
  const query = queryString.parse(location.search);

  //Toggle the particular dropdown clicked
  const handleDropdownShown = (event, i) => {
    if (event.target.closest('div').dataset.drop !== 'true') return;
    setDropdownShown((prevValues) =>
      prevValues.map((val, index) => {
        if (i === index) val = !val;
        return val;
      })
    );
  };

  return (
    <Sidebar active={hamburgerActive}>
      <SidebarContainer>
        <SidebarNavItemLink to={`${match.path}`}>
          <SidebarNavItemText>
            <Icon
              iconName="list2"
              big
              active={match.path === '/dashboard' && !query.tab}
              style={{ height: '2rem' }}
            />
            <span>Overview</span>
          </SidebarNavItemText>
        </SidebarNavItemLink>

        {user.role === 'admin' && (
          <Fragment>
            <SidebarNavItemLink to={`${match.path}?tab=all-clients`}>
              <SidebarNavItemText>
                <Icon
                  iconName="consumer"
                  big
                  active={query.tab === 'all-clients'}
                />
                <span>Clients</span>
              </SidebarNavItemText>
            </SidebarNavItemLink>

            <SidebarNavItemLink to={`${match.path}?tab=all-tutors`}>
              <SidebarNavItemText>
                <Icon
                  iconName="tutor"
                  big
                  active={query.tab === 'all-tutors'}
                />
                <span>Tutors</span>
              </SidebarNavItemText>
            </SidebarNavItemLink>

            <SidebarNavItemDiv
              onClick={(event) => handleDropdownShown(event, 3)}
            >
              <SidebarNavItemText
                data-drop="true"
                dropdownShown={dropdownShown[3]}
              >
                <Icon
                  iconName="work-engagement"
                  big
                  active={
                    query.tab === 'all-engagements' ||
                    query.tab === 'add-engagement' ||
                    query.tab === 'edit-engagement'
                  }
                />

                <span>Engagements</span>

                <Icon iconName="keyboard_arrow_right" medium />
              </SidebarNavItemText>

              <NavDropdown dropdownShown={dropdownShown[3]}>
                <NavDropdownItem
                  to={`${match.path}?tab=all-engagements`}
                  style={{ marginLeft: 0 }}
                >
                  <Icon
                    iconName="keyboard_arrow_right"
                    medium
                    active={query.tab === 'all-engagements'}
                  />

                  <span>All Engagements</span>
                </NavDropdownItem>

                <NavDropdownItem to={`${match.path}?tab=add-engagement`}>
                  <Icon
                    iconName="pencil"
                    active={query.tab === 'add-engagement'}
                  />

                  <span>Add Engagement</span>
                </NavDropdownItem>
              </NavDropdown>
            </SidebarNavItemDiv>
          </Fragment>
        )}

        <SidebarNavItemDiv onClick={(event) => handleDropdownShown(event, 0)}>
          <SidebarNavItemText data-drop="true" dropdownShown={dropdownShown[0]}>
            <Icon
              iconName="report"
              big
              active={
                query.tab === 'all-reports' ||
                query.tab === 'create-report' ||
                query.tab === 'edit-report'
              }
            />
            <span>Progress Reports</span>
            <Icon iconName="keyboard_arrow_right" medium />
          </SidebarNavItemText>
          <NavDropdown dropdownShown={dropdownShown[0]}>
            <NavDropdownItem to={`${match.path}?tab=all-reports`}>
              <Icon
                iconName="keyboard_arrow_right"
                medium
                active={query.tab === 'all-reports'}
              />
              <span>All Reports</span>
            </NavDropdownItem>
            <NavDropdownItem to={`${match.path}?tab=create-report`}>
              <Icon iconName="pencil" active={query.tab === 'create-report'} />
              <span>Create Report</span>
            </NavDropdownItem>
          </NavDropdown>
        </SidebarNavItemDiv>

        {/* <SidebarNavItemDiv onClick={(event) => handleDropdownShown(event, 1)}>
          <SidebarNavItemText data-drop="true" dropdownShown={dropdownShown[1]}>
            <Icon
              iconName="meeting"
              big
              active={
                query.tab === 'all-sessions' || query.tab === 'record-session'
              }
            />
            <span>Sessions</span>
            <Icon iconName="keyboard_arrow_right" medium />
          </SidebarNavItemText>
          <NavDropdown dropdownShown={dropdownShown[1]}>
            <NavDropdownItem to={`${match.path}?tab=all-sessions`}>
              <Icon
                iconName="keyboard_arrow_right"
                medium
                active={query.tab === 'all-sessions'}
              />
              <span>All Sessions</span>
            </NavDropdownItem>
            <NavDropdownItem to={`${match.path}?tab=record-session`}>
              <Icon iconName="camera" active={query.tab === 'record-session'} />
              <span>Record Session</span>
            </NavDropdownItem>
          </NavDropdown>
        </SidebarNavItemDiv> */}

        <SidebarNavItemLink
          to={`${match.path}?tab=${
            user.role === 'tutor' ? 'create-checklist' : 'all-checklists'
          }`}
        >
          <SidebarNavItemText>
            <Icon
              iconName="check-list"
              big
              active={
                query.tab === 'all-checklists' ||
                query.tab === 'create-checklist'
              }
            />
            <span>Weekly Checklist</span>
          </SidebarNavItemText>
        </SidebarNavItemLink>
      </SidebarContainer>
    </Sidebar>
  );
};

const mapStateToProps = createStructuredSelector({
  hamburgerActive: (state) => state.app.dashboardHamburgerActive,
  user: selectCurrentUser,
});

export default connect(mapStateToProps)(withRouter(DashboardSidebar));
