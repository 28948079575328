import { useState } from 'react';
import { connect } from 'react-redux';

import {
  LoginFormContainer,
  LoginFormLogo,
  LoginFormText,
  Form,
} from './login-form.styles';

import { FormInputContainer } from '../input-container/input-container.styles';

import InputContainer from '../input-container/input-container.component';
import CustomButton from '../custom-button/custom-button.component';

import { emailSignInStart } from '../../Redux/user/user.actions';
import Loading from '../loading/loading.component';

const LoginForm = ({ emailSignInStart, loading }) => {
  const [userDetails, setUserDetails] = useState({
    email: '',
    password: '',
  });

  const { email, password } = userDetails;

  const handleChange = (event) => {
    //Get name and value from event.target
    const { name, value } = event.target;

    //Update the appropriate value
    setUserDetails((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = (event) => {
    //Prevent default submit behavior
    event.preventDefault();

    //Start the sign in process
    emailSignInStart(email, password);
  };

  return (
    <LoginFormContainer>
      <LoginFormLogo src="/img/gate-logo.png" alt="GATE" />
      <LoginFormText>Log in to your account</LoginFormText>
      <Form onSubmit={handleSubmit}>
        <InputContainer
          name="email"
          type="email"
          label="Email Address"
          required
          placeholder=" "
          autoFocus
          icon="#icon-user"
          value={email}
          onChange={handleChange}
        />
        <InputContainer
          name="password"
          type="password"
          label="Password"
          required
          placeholder=" "
          icon="#icon-key"
          minLength="8"
          value={password}
          onChange={handleChange}
        />
        <FormInputContainer centered>
          <CustomButton type="submit" smallerBorder disabled={loading}>
            {!loading ? 'Log In' : <Loading type="bouncy dots" />}
          </CustomButton>
        </FormInputContainer>
      </Form>
    </LoginFormContainer>
  );
};

const mapStateToProps = (state) => ({
  loading: state.user.emailSignInLoading,
});

const mapDispatchToProps = (dispatch) => ({
  emailSignInStart: (email, password) =>
    dispatch(emailSignInStart({ email, password })),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
