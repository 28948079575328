import { useEffect } from 'react';
import { connect } from 'react-redux';
import { toast, Slide } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';
import { StyledToastContainer } from './alert.styles';

import { removeAlert } from '../../Redux/app/app.actions';

const Alert = ({ alert, removeAlert }) => {
  useEffect(() => {
    if (alert) {
      toast[alert.type](alert.message, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => removeAlert(),
      });
    }
  }, [alert, removeAlert]);

  return (
    <StyledToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      limit={6}
      transition={Slide}
    />
  );
};

const mapStateToProps = (state) => ({
  alert: state.app.alert,
});

const mapDispatchToProps = (dispatch) => ({
  removeAlert: () => dispatch(removeAlert()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
