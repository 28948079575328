import WeeklyChecklistActionTypes from './weekly-checklist.types';

import { sortChecklists } from './weekly-checklist.utils';

const INITIAL_STATE = {
    sendingWChecklist: false,
    sentWChecklist: false,
    gettingChecklists: false,
    gottenChecklists: false,
    checklists: null,
    sortedChecklists: null
};


const weeklyChecklistReducer = (currentState = INITIAL_STATE, action) => {
    switch (action.type) {
        case WeeklyChecklistActionTypes.SEND_W_CHECKLIST_START:
            return {
                ...currentState,
                sendingWChecklist: true,
                sentWChecklist: false,
            };
        case WeeklyChecklistActionTypes.GET_W_CHECKLISTS_START:
            return {
                ...currentState,
                gettingChecklists: true,
            };
        case WeeklyChecklistActionTypes.SEND_W_CHECKLIST_SUCCESS:
            return {
                ...currentState,
                sendingWChecklist: false,
                sentWChecklist: true,
            };
        case WeeklyChecklistActionTypes.GET_W_CHECKLISTS_SUCCESS:
            return {
                ...currentState,
                gettingChecklists: false,
                gottenChecklists: true,
                checklists: [...action.payload],
            };
        case WeeklyChecklistActionTypes.SORT_W_CHECKLISTS:
            return {
                ...currentState,
                sortedChecklists: {...sortChecklists(action.payload) }
            }
        case WeeklyChecklistActionTypes.SEND_W_CHECKLIST_FAILURE:
            return {
                ...currentState,
                sendingWChecklist: false,
                sentWChecklist: false,
            };
        case WeeklyChecklistActionTypes.GET_W_CHECKLISTS_FAILURE:
            return {
                ...currentState,
                gettingChecklists: false,
                gottenChecklists: false,
                checklists: null,
            };

        default:
            return {
                ...currentState,
            };
    }
};

export default weeklyChecklistReducer;