import { connect } from 'react-redux';

import { HamburgerIcon, HamburgerContainer } from './hamburger.styles';

import { dashboardHamburgerClicked } from '../../Redux/app/app.actions';

const Hamburger = ({ hamburgerActive, setHamburgerActive }) => {
  return (
    <HamburgerContainer onClick={() => setHamburgerActive(hamburgerActive)}>
      <HamburgerIcon active={hamburgerActive} />
    </HamburgerContainer>
  );
};

const mapStateToProps = (state) => ({
  hamburgerActive: state.app.dashboardHamburgerActive,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
  setHamburgerActive: (val) => dispatch(dashboardHamburgerClicked(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Hamburger);
