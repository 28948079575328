import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Header,
  HeaderContainer,
  HeaderLogo,
  HeaderText,
  HeaderUser,
} from './dashboard-header.styles';

import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../Redux/user/user.selectors';

import { signOutStart } from '../../Redux/user/user.actions';

import Icon from '../icon/icon.component';
import Hamburger from '../hamburger/hamburger.component';
import MoreOptions from '../more-options/more-options.component';

const DashboardHeader = ({ currentUser, signOutStart }) => {
  let history = useHistory();

  return (
    <HeaderContainer>
      <Hamburger />

      <HeaderLogo src="/img/gate-logo.png" alt="GATE Academy" />

      <Header>
        <MoreOptions
          contentZIndex={1999}
          trigger={
            <HeaderUser>
              <Icon
                iconName="user-1"
                style={{ height: '1.8rem' }}
                medium
                black
              />

              <HeaderText>Hi, {currentUser.name.split(' ')[1]}</HeaderText>

              <Icon iconName="keyboard_arrow_down" medium black />
            </HeaderUser>
          }
          position="bottom right"
          options={[
            {
              action: () => history.push('/dashboard?tab=your-profile'),
              iconName: 'user-1',
              label: 'Your profile',
            },
            {
              action: () => history.push('/dashboard?tab=update-password'),
              iconName: 'settings',
              label: 'Your settings',
            },
            {
              action: () => signOutStart(currentUser.role),
              iconName: 'power_settings_new',
              label: 'Logout',
            },
          ]}
        />
      </Header>
    </HeaderContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  signOutStart: (role) => dispatch(signOutStart(role)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);
