import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const activeSidebar = css`
  width: 29rem;
  opacity: 1;
  visibility: visible;
  z-index: 10;
`;

export const Sidebar = styled.nav`
  width: 29rem;
  background-image: linear-gradient(145deg, #0257ac, #012850);
  position: fixed;
  top: 6rem;
  left: 0;
  height: 100vh;
  color: #fff;
  font-size: 2rem;
  transition-property: opacity, visibility, width;
  transition-duration: 0.1s;
  @media only screen and (max-width: 62.5em) {
    width: 0;
    opacity: 0;
    visibility: hidden;
    ${(props) => (props.active ? activeSidebar : '')}
  }
`;

export const SidebarContainer = styled.div`
  max-height: 85%;
  height: 100%;
  overflow-y: auto;
  overflow-y: overlay;
  overflow-x: hidden;
  scrollbar-width: thin;
  position: relative;

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* scrollbar track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* scrollbar handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(117, 117, 117, 0.5);
  }

  /* scrollbar handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(117, 117, 117, 1);
  }
`;

export const SidebarNavItemLink = styled(Link)`
  padding: 1rem 0;
  position: relative;
  height: 6.5rem;
  &::before {
    content: '';
    position: absolute;
    top: 6.5rem;
    height: 2px;
    width: 29rem;
    background-color: #012850;
    @-moz-document url-prefix() {
      & {
        top: 9.5rem;
      }
    }
  }
  & > div {
    padding: 1.75rem 2rem;
  }
`;

export const SidebarNavItemDiv = styled.div`
  padding: 1rem 0;
  position: relative;
  &:last-child {
    margin-bottom: 8rem;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 100%;
    background-color: #012850;
  }
`;

export const SidebarNavItemText = styled.div`
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  & > span {
    margin: 0 3rem;
    & + svg {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      transition: transform 0.1s;
    }
  }
  ${(props) =>
    props.dropdownShown
      ? '&>svg:last-of-type{transform: translateY(-50%) rotate(90deg);}'
      : ''}
`;

export const NavDropdown = styled.ul`
  position: relative;
  height: 0;
  list-style: none;
  background-color: #012850;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 4rem;
  font-size: 1.7rem;
  margin-bottom: -1.5rem;
  transition: visibility, height;
  ${(props) => (props.dropdownShown ? 'height: 100%;visibility: visible;' : '')}
`;

export const NavDropdownItem = styled(Link)`
  padding: 1rem 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  & > span {
    margin-left: 1rem;
  }
  &:first-child {
    margin-left: -2.5rem;
  }
`;
