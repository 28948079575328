import TutorActionTypes from './tutor.types';

export const getRecentTutorsStart = () => ({
    type: TutorActionTypes.GET_RECENT_TUTORS_START,
});

export const getRecentTutorsSuccess = (results) => ({
    type: TutorActionTypes.GET_RECENT_TUTORS_SUCCESS,
    payload: results,
});

export const getRecentTutorsFailure = () => ({
    type: TutorActionTypes.GET_RECENT_TUTORS_FAILURE,
});

export const addTutorStart = (tutor) => ({
    type: TutorActionTypes.ADD_TUTOR_START,
    payload: tutor,
});

export const addTutorSuccess = (tutor) => ({
    type: TutorActionTypes.ADD_TUTOR_SUCCESS,
    payload: tutor,
});

export const addTutorFailure = () => ({
    type: TutorActionTypes.ADD_TUTOR_FAILURE,
});

export const setAddedToFalse = () => ({
    type: TutorActionTypes.SET_ADDED_TO_FALSE,
});

export const updateTutorStart = (tutor) => ({
    type: TutorActionTypes.UPDATE_TUTOR_START,
    payload: tutor,
});

export const updateTutorSuccess = (response) => ({
    type: TutorActionTypes.UPDATE_TUTOR_SUCCESS,
    payload: response,
});

export const updateTutorFailure = () => ({
    type: TutorActionTypes.UPDATE_TUTOR_FAILURE,
});

export const setUpdatedToFalse = () => ({
    type: TutorActionTypes.SET_UPDATED_TO_FALSE,
});

export const searchTutorsStart = (search) => ({
    type: TutorActionTypes.SEARCH_TUTORS_START,
    payload: search,
});

export const searchTutorsSuccess = (results) => ({
    type: TutorActionTypes.SEARCH_TUTORS_SUCCESS,
    payload: results,
});

export const searchTutorsFailure = () => ({
    type: TutorActionTypes.SEARCH_TUTORS_FAILURE,
});

export const clearSearchTutors = () => ({
    type: TutorActionTypes.CLEAR_SEARCH_TUTORS,
});

export const loadMoreTutorsStart = (query) => ({
    type: TutorActionTypes.LOAD_MORE_TUTORS_START,
    payload: query,
});

export const loadMoreTutorsSuccess = (results) => ({
    type: TutorActionTypes.LOAD_MORE_TUTORS_SUCCESS,
    payload: results,
});

export const loadMoreTutorsFailure = (pageNo) => ({
    type: TutorActionTypes.LOAD_MORE_TUTORS_FAILURE,
    payload: pageNo,
});

export const loadLessTutors = () => ({
    type: TutorActionTypes.LOAD_LESS_TUTORS,
});

export const sendResetPasswordStart = ({ id, email }) => ({
    type: TutorActionTypes.SEND_RESET_PASSWORD_START,
    payload: { id, email },
});

export const sendResetPasswordSuccess = (id) => ({
    type: TutorActionTypes.SEND_RESET_PASSWORD_SUCCESS,
    payload: id,
});

export const sendResetPasswordFailure = (id) => ({
    type: TutorActionTypes.SEND_RESET_PASSWORD_FAILURE,
    payload: id,
});

export const setSendingResetPasswordObject = (array) => ({
    type: TutorActionTypes.SET_SENDING_RESET_PASSWORD_OBJECT,
    payload: array,
});