import UserActionTypes from './user.types';

// export const googleSignInStart = () => ({
//   type: UserActionTypes.GOOGLE_SIGN_IN_START,
// });

export const emailSignInStart = (emailAndPassword) => ({
    type: UserActionTypes.EMAIL_SIGN_IN_START,
    payload: emailAndPassword,
});

export const signInSuccess = (user) => ({
    type: UserActionTypes.SIGN_IN_SUCCESS,
    payload: user,
});

export const signInFailure = () => ({
    type: UserActionTypes.SIGN_IN_FAILURE,
});

export const signOutStart = (role) => ({
    type: UserActionTypes.SIGN_OUT_START,
    payload: role,
});

export const signOutSuccess = () => ({
    type: UserActionTypes.SIGN_OUT_SUCCESS,
});

export const signOutFailure = () => ({
    type: UserActionTypes.SIGN_OUT_FAILURE,
});

export const checkUserSession = () => ({
    type: UserActionTypes.CHECK_USER_SESSION,
});

export const setCurrentUser = (user) => ({
    type: UserActionTypes.SET_CURRENT_USER,
    payload: user,
});

export const updateCurrentUserStart = ({ role, obj }) => ({
    type: UserActionTypes.UPDATE_CURRENT_USER_START,
    payload: { role, obj },
});

export const updateCurrentUserSuccess = (user) => ({
    type: UserActionTypes.UPDATE_CURRENT_USER_SUCCESS,
    payload: user,
});

export const updateCurrentUserFailure = () => ({
    type: UserActionTypes.UPDATE_CURRENT_USER_FAILURE,
});

// export const signUpStart = (userDetails) => ({
//   type: UserActionTypes.SIGN_UP_START,
//   payload: userDetails,
// });

// export const signUpSuccess = ({ user, additionalData }) => ({
//   type: UserActionTypes.SIGN_UP_SUCCESS,
//   payload: { user, additionalData },
// });

// export const signUpFailure = (message) => ({
//   type: UserActionTypes.SIGN_UP_FAILURE,
//   payload: message,
// });

export const getOverviewStatsStart = () => ({
    type: UserActionTypes.GET_OVERVIEW_STATS_START,
});

export const getOverviewStatsSuccess = () => ({
    type: UserActionTypes.GET_OVERVIEW_STATS_SUCCESS,
});

export const getOverviewStatsFailure = () => ({
    type: UserActionTypes.GET_OVERVIEW_STATS_FAILURE,
});

export const updateCurrentUserPasswordStart = ({ role, obj }) => ({
    type: UserActionTypes.UPDATE_CURRENT_USER_PASSWORD_START,
    payload: { role, obj },
});

export const updateCurrentUserPasswordSuccess = (user) => ({
    type: UserActionTypes.UPDATE_CURRENT_USER_PASSWORD_SUCCESS,
    payload: user,
});

export const updateCurrentUserPasswordFailure = () => ({
    type: UserActionTypes.UPDATE_CURRENT_USER_PASSWORD_FAILURE,
});