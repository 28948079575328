import { takeLatest, put, call, all } from 'redux-saga/effects';
import axios from 'axios';

import ClientActionTypes from './client.types';

import { setAlert } from '../app/app.actions';

import {
    addClientFailure,
    addClientSuccess,
    getRecentClientsSuccess,
    loadMoreClientsFailure,
    loadMoreClientsSuccess,
    searchClientsFailure,
    searchClientsSuccess,
    updateClientFailure,
    updateClientSuccess,
} from './client.actions';

export function* getRecentClients() {
    try {
        //To get the total clients and the recent clients
        const res1 = yield axios.get('api/v1/clients');

        //To get the total active clients
        const res2 = yield axios.get('api/v1/clients?engaged=true&fields=_id');

        const results = {
            totalClients: res1.data.totalDocuments,
            totalActive: res2.data.totalDocuments,
            data: res1.data.data.data,
        };

        yield put(getRecentClientsSuccess(results));
    } catch (err) {
        yield put(
            setAlert({ message: err.response.data.message, type: 'error' })
        );
    }
}

export function* addClient({ payload }) {
    try {
        const res = yield axios.post('api/v1/clients', payload);

        yield put(addClientSuccess(res.data.data.data));
    } catch (err) {
        yield put(addClientFailure());
        yield put(
            setAlert({ message: err.response.data.message, type: 'error' })
        );
    }
}

export function* updateClient({ payload }) {
    try {
        const res1 = yield axios.patch(`api/v1/clients/${payload._id}`, payload);

        //To get the total active clients again
        const res2 = yield axios.get('api/v1/clients?engaged=true&fields=_id');

        yield put(
            updateClientSuccess({
                data: res1.data.data.data,
                totalActive: res2.data.totalDocuments,
            })
        );
    } catch (err) {
        yield put(updateClientFailure());
        yield put(
            setAlert({ message: err.response.data.message, type: 'error' })
        );
    }
}

export function* searchClient({ payload }) {
    let query;
    const { searchTerm, searchBy } = payload;

    switch (searchBy) {
        case 'surname':
            query = `familyName[regex]=${searchTerm}&familyName[options]=i`;
            break;
        case 'wardName':
            query = `wards__name[regex]=${searchTerm}&wards__name[options]=i`;
            break;
        case 'wardClass':
            query = `wards__class[regex]=${searchTerm}&wards__class[options]=i`;
            break;
        case 'engaged':
            query = `engaged=true`;
            break;
        case 'notEngaged':
            query = `engaged=false`;
            break;
        default:
            return;
    }

    try {
        const res = yield axios.get(`/api/v1/clients?${query}`);

        yield put(searchClientsSuccess(res.data));
    } catch (err) {
        yield put(searchClientsFailure());
        yield put(
            setAlert({ message: err.response.data.message, type: 'error' })
        );
    }
}

export function* loadMoreClients({ payload }) {
    let query;
    const { searchTerm, searchBy, page } = payload;

    switch (searchBy) {
        case 'surname':
            query = `familyName[regex]=${searchTerm}&familyName[options]=i`;
            break;
        case 'wardName':
            query = `wards__name[regex]=${searchTerm}&wards__name[options]=i`;
            break;
        case 'wardClass':
            query = `wards__class[regex]=${searchTerm}&wards__class[options]=i`;
            break;
        case 'engaged':
            query = `engaged=true`;
            break;
        case 'notEngaged':
            query = `engaged=false`;
            break;
        default:
            return;
    }

    try {
        const res = yield axios.get(`/api/v1/clients?${query}&page=${page}`);

        yield put(loadMoreClientsSuccess(res.data));
    } catch (err) {
        yield put(loadMoreClientsFailure(page - 1));
        yield put(
            setAlert({ message: err.response.data.message, type: 'error' })
        );
    }
}

export function* onGetRecentClientsStart() {
    yield takeLatest(
        ClientActionTypes.GET_RECENT_CLIENTS_START,
        getRecentClients
    );
}

export function* onAddClientStart() {
    yield takeLatest(ClientActionTypes.ADD_CLIENT_START, addClient);
}

export function* onUpdateClientStart() {
    yield takeLatest(ClientActionTypes.UPDATE_CLIENT_START, updateClient);
}

export function* onSearchClientStart() {
    yield takeLatest(ClientActionTypes.SEARCH_CLIENTS_START, searchClient);
}

export function* onLoadMoreClientsStart() {
    yield takeLatest(ClientActionTypes.LOAD_MORE_CLIENTS_START, loadMoreClients);
}

export function* clientSagas() {
    yield all([
        call(onGetRecentClientsStart),
        call(onAddClientStart),
        call(onUpdateClientStart),
        call(onSearchClientStart),
        call(onLoadMoreClientsStart),
    ]);
}