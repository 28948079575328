import { useEffect } from 'react';

import { LoginPageContainer } from './login-page.styles';

import LoginForm from '../../Components/login-form/login-form.component';

const LoginPage = () => {
  //Change document.title whenever this component mounts
  useEffect(() => {
    document.title = 'GATE Academy | Log in';
  });

  return (
    <LoginPageContainer img="/img/woman-and-child-in-aquarium.jpg">
      <LoginForm />
    </LoginPageContainer>
  );
};

export default LoginPage;
