import { useState, useEffect, lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import queryString from 'query-string';

import { Main, Title } from './dashboard-page.styles';

import { selectCurrentUser } from '../../Redux/user/user.selectors';

import DashboardHeader from '../../Components/dashboard-header/dashboard-header.component';
import DashboardSidebar from '../../Components/dashboard-sidebar/dashboard-sidebar.component';
import Profile from '../../Components/profile/profile.component';
import Loading from '../../Components/loading/loading.component';

import ScrollToTopOnMount from '../../scrollToTopOnMount';

import { setReportToEdit } from '../../Redux/progress-report/progress-report.actions';
import { setEngagementToEdit } from '../../Redux/engagements/engagements.actions';

import { dashboardHamburgerClicked } from '../../Redux/app/app.actions';

const AllClients = lazy(() =>
  import('../../Components/all-clients-all-tutors/all-clients.component')
);
const AllTutors = lazy(() =>
  import('../../Components/all-clients-all-tutors/all-tutors.component')
);
const AllReportsAdmin = lazy(() =>
  import('../../Components/progress-reports/progress-reports-admin.component')
);
const AllReports = lazy(() =>
  import('../../Components/progress-reports/progress-reports.component')
);
const AllWeeklyChecklists = lazy(() =>
  import('../../Components/all-weekly-checklist/all-weekly-checklist.component')
);
const CreateWeeklyChecklist = lazy(() =>
  import(
    '../../Components/create-weekly-checklist/create-weekly-checklist.component'
  )
);
const ProgressReportFormUpdate = lazy(() =>
  import(
    '../../Components/progress-report-form/progress-report-update-form.component'
  )
);
const ProgressReportForm = lazy(() =>
  import('../../Components/progress-report-form/progress-report-form.component')
);
const DashboardOverview = lazy(() =>
  import('../../Components/dashboard-overview/dashboard-overview.component')
);
const DashboardOverviewAdmin = lazy(() =>
  import(
    '../../Components/dashboard-overview/dashboard-overview-admin.component'
  )
);
const UpdatePassword = lazy(() =>
  import('../../Components/update-password/update-password.component')
);
const ProgressReportsContainer = lazy(() =>
  import('../../Components/progress-reports/progress-reports.container')
);
const AllEngagements = lazy(() =>
  import('../../Components/all-engagements/all-engagements.component')
);
const AddEngagement = lazy(() =>
  import('../../Components/add-edit-engagement/add-engagement.component')
);
const EditEngagement = lazy(() =>
  import('../../Components/add-edit-engagement/edit-engagement.component')
);

const Dashboard = ({
  history,
  location,
  hamburgerActive,
  setHamburgerActive,
  currentUser,
  reportToEdit,
  setReportToEdit,
  engagementToEdit,
  setEngagementToEdit,
}) => {
  //Initialize title state
  const [title, setTitle] = useState('');

  //Parse query string
  const query = queryString.parse(location.search);

  //Update title and document.title whenever query.tab changes
  useEffect(() => {
    if (!query.tab) {
      setTitle('Overview');
    } else if (query.tab === 'your-profile') {
      setTitle('Your Profile');
    } else if (query.tab === 'all-reports') {
      setTitle('All Reports');
    } else if (query.tab === 'create-report') {
      setTitle('Create Report');
    } else if (query.tab === 'edit-report') {
      setTitle('Edit Report');
    } else if (query.tab === 'all-sessions') {
      setTitle('All Sessions');
    } else if (query.tab === 'record-session') {
      setTitle('Record Session');
    } else if (query.tab === 'all-clients') {
      setTitle('All Clients');
    } else if (query.tab === 'all-tutors') {
      setTitle('All Tutors');
    } else if (query.tab === 'create-checklist') {
      setTitle('Create Weekly Checklist');
    } else if (query.tab === 'all-checklists') {
      setTitle('All Weekly Checklists');
    } else if (query.tab === 'update-password') {
      setTitle('Update Password');
    } else if (query.tab === 'all-engagements') {
      setTitle('All Engagements');
    } else if (query.tab === 'add-engagement') {
      setTitle('Add Engagement');
    } else if (query.tab === 'edit-engagement') {
      setTitle('Edit Engagement');
    }

    //Remove reportToEdit if a different route is hit
    if (query.tab !== 'edit-report' && reportToEdit) setReportToEdit(null);

    //Remove engagementToEdit if a different route is hit
    if (query.tab !== 'edit-engagement' && engagementToEdit)
      setEngagementToEdit(null);

    //Redirect to all-reports tab if edit-report is hit and there is no report to edit
    if (query.tab === 'edit-report' && !reportToEdit)
      history.push('/dashboard?tab=all-reports');

    //Redirect to all-engagements tab if edit-engagement is hit and there is no engagement to edit
    if (query.tab === 'edit-engagement' && !engagementToEdit)
      history.push('/dashboard?tab=all-engagements');

    if (
      (query.tab === 'all-clients' ||
        query.tab === 'all-tutors' ||
        query.tab === 'all-engagements' ||
        query.tab === 'add-engagement' ||
        query.tab === 'edit-engagement') &&
      currentUser.role !== 'admin'
    )
      history.push('/dashboard');

    if (query.tab === 'all-checklists' && currentUser.role === 'tutor')
      history.push('/dashboard?tab=create-checklist');

    document.title = `GATE Academy | ${title}`;
  }, [
    query.tab,
    title,
    history,
    reportToEdit,
    setReportToEdit,
    currentUser,
    engagementToEdit,
    setEngagementToEdit,
  ]);

  return (
    <>
      <DashboardHeader />
      <DashboardSidebar />
      <Main
        onClick={() => {
          //Check if hamburger is active, if it is, make it disappear
          if (hamburgerActive) return setHamburgerActive(hamburgerActive);
        }}
      >
        <Title>{title}</Title>

        <Suspense
          fallback={
            <>
              <ScrollToTopOnMount />

              <Loading type="infinite spinner" />
            </>
          }
        >
          <Route
            render={() => {
              //Render component based on query.tab
              if (!query.tab) {
                if (currentUser.role === 'admin')
                  return <DashboardOverviewAdmin />;
                return <DashboardOverview />;
              } else if (query.tab === 'your-profile') {
                return <Profile />;
              } else if (query.tab === 'all-reports') {
                if (currentUser.role === 'admin')
                  return (
                    <ProgressReportsContainer Component={AllReportsAdmin} />
                  );
                return <ProgressReportsContainer Component={AllReports} />;
              } else if (query.tab === 'create-report') {
                return <ProgressReportForm />;
              } else if (query.tab === 'edit-report' && reportToEdit) {
                return <ProgressReportFormUpdate />;
              } else if (query.tab === 'all-clients') {
                return <AllClients />;
              } else if (query.tab === 'all-tutors') {
                return <AllTutors />;
              } else if (query.tab === 'create-checklist') {
                return <CreateWeeklyChecklist />;
              } else if (query.tab === 'all-checklists') {
                return <AllWeeklyChecklists />;
              } else if (query.tab === 'update-password') {
                return <UpdatePassword />;
              } else if (query.tab === 'all-engagements') {
                return <AllEngagements />;
              } else if (query.tab === 'add-engagement') {
                return <AddEngagement />;
              } else if (query.tab === 'edit-engagement' && engagementToEdit) {
                return <EditEngagement engagement={engagementToEdit} />;
              }
            }}
          />
        </Suspense>
      </Main>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  hamburgerActive: (state) => state.app.dashboardHamburgerActive,
  currentUser: selectCurrentUser,
  reportToEdit: (state) => state.progressReport.reportToEdit,
  engagementToEdit: (state) => state.engagement.engagementToEdit,
});

const mapDispatchToProps = (dispatch) => ({
  setHamburgerActive: (val) => dispatch(dashboardHamburgerClicked(val)),
  setReportToEdit: (report) => dispatch(setReportToEdit(report)),
  setEngagementToEdit: (engagement) =>
    dispatch(setEngagementToEdit(engagement)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
