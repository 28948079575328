import { takeLatest, put, call, all } from 'redux-saga/effects';
import WeeklyChecklistActionTypes from './weekly-checklist.types';
import axios from 'axios';

import { setAlert } from '../app/app.actions';

import {
    sendChecklistSuccess,
    sendChecklistFailure,
    getChecklistsSuccess,
    getChecklistsFailure,
} from './weekly-checklist.actions';

export function* sendWChecklist({ payload }) {
    try {
        yield axios.post('/api/v1/weekly-checklist', payload);
        yield put(sendChecklistSuccess());
        yield put(
            setAlert({
                message: 'The checklist is on its way. Thank you.',
                type: 'success',
            })
        );
    } catch (err) {
        yield put(sendChecklistFailure());

        //Give generic message if error is for a duplicate document entry
        if (err.response.data.message.includes('already exists'))
            return yield put(
                setAlert({
                    message: 'Oooops!! This checklist already exists.',
                    type: 'error',
                })
            );

        yield put(setAlert({ message: err.response.data.message, type: 'error' }));
    }
}

export function* getWChecklists() {
    try {
        const res = yield axios.get('/api/v1/weekly-checklist');
        yield put(getChecklistsSuccess(res.data.data.data));
    } catch (err) {
        yield put(getChecklistsFailure());
        yield put(setAlert({ message: err.response.data.message, type: 'error' }));
    }
}

export function* onSendChecklistStart() {
    yield takeLatest(
        WeeklyChecklistActionTypes.SEND_W_CHECKLIST_START,
        sendWChecklist
    );
}

export function* onGetChecklistsStart() {
    yield takeLatest(
        WeeklyChecklistActionTypes.GET_W_CHECKLISTS_START,
        getWChecklists
    );
}

export function* weeklyChecklistSagas() {
    yield all([call(onSendChecklistStart), call(onGetChecklistsStart)]);
}