import EngagementActionTypes from './engagements.types';

export const getRecentEngagementsStart = () => ({
    type: EngagementActionTypes.GET_RECENT_ENGAGEMENTS_START,
});

export const getRecentEngagementsSuccess = (data) => ({
    type: EngagementActionTypes.GET_RECENT_ENGAGEMENTS_SUCCESS,
    payload: data,
});

export const getRecentEngagementsFailure = () => ({
    type: EngagementActionTypes.GET_RECENT_ENGAGEMENTS_FAILURE,
});

export const addEngagementStart = (engagement) => ({
    type: EngagementActionTypes.ADD_ENGAGEMENT_START,
    payload: engagement,
});

export const addEngagementSuccess = (data) => ({
    type: EngagementActionTypes.ADD_ENGAGEMENT_SUCCESS,
    payload: data,
});

export const addEngagementFailure = () => ({
    type: EngagementActionTypes.ADD_ENGAGEMENT_FAILURE,
});

export const setAddedEngagementToFalse = () => ({
    type: EngagementActionTypes.SET_ADDED_ENGAGEMENT_TO_FALSE,
});

export const setEngagementToEdit = (engagement) => ({
    type: EngagementActionTypes.SET_ENGAGEMENT_TO_EDIT,
    payload: engagement,
});

export const updateEngagementStart = (engagement) => ({
    type: EngagementActionTypes.UPDATE_ENGAGEMENT_START,
    payload: engagement,
});

export const updateEngagementSuccess = (engagement) => ({
    type: EngagementActionTypes.UPDATE_ENGAGEMENT_SUCCESS,
    payload: engagement,
});

export const updateEngagementFailure = () => ({
    type: EngagementActionTypes.UPDATE_ENGAGEMENT_FAILURE,
});

export const searchEngagementsStart = (searchObj) => ({
    type: EngagementActionTypes.SEARCH_ENGAGEMENTS_START,
    payload: searchObj,
});

export const searchEngagementsSuccess = (data) => ({
    type: EngagementActionTypes.SEARCH_ENGAGEMENTS_SUCCESS,
    payload: data,
});

export const searchEngagementsFailure = () => ({
    type: EngagementActionTypes.SEARCH_ENGAGEMENTS_FAILURE,
});

export const clearEngagementsSearch = () => ({
    type: EngagementActionTypes.CLEAR_ENGAGEMENTS_SEARCH,
});

export const loadMoreEngagementsStart = (query) => ({
    type: EngagementActionTypes.LOAD_MORE_ENGAGEMENTS_START,
    payload: query,
});

export const loadMoreEngagementsSuccess = (data) => ({
    type: EngagementActionTypes.LOAD_MORE_ENGAGEMENTS_SUCCESS,
    payload: data,
});

export const loadMoreEngagementsFailure = (pageNo) => ({
    type: EngagementActionTypes.LOAD_MORE_ENGAGEMENTS_FAILURE,
    payload: pageNo,
});

export const loadLessEngagements = () => ({
    type: EngagementActionTypes.LOAD_LESS_ENGAGEMENTS,
});