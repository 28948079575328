import { put, takeLatest, all, call, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import ProgressReportActionTypes from './progress-report.types';

import {
    sendProgressReportSuccess,
    sendProgressReportFailure,
    updateProgressReportSuccess,
    updateProgressReportFailure,
    approveReportSuccess,
    approveReportFailure,
    commentOnReportFailure,
    commentOnReportSuccess,
    getProgressReportsFailure,
    getProgressReportsSuccess,
    loadMoreReportsFailure,
    loadMoreReportsSuccess,
    getRecentProgressReportsFailure,
    getRecentProgressReportsSuccess,
    getPrevCurrentReportsCountSuccess,
    getPrevCurrentReportsCountFailure,
    getPrevCurrentReportsCountStart,
} from './progress-report.actions';

import { setAlert } from '../app/app.actions';

export function* sendReport({ payload }) {
    const { report, role } = payload;

    try {
        const response = yield axios.post('/api/v1/progress-reports', report);
        yield put(
            setAlert({
                message: 'The report is on its way ✈!! Thank you',
                type: 'success',
            })
        );
        yield put(
            sendProgressReportSuccess({
                report: {...response.data.data.data },
                role,
            })
        );

        if (role === 'tutor') yield put(getPrevCurrentReportsCountStart());
    } catch (err) {
        yield put(
            setAlert({ message: err.response.data.message, type: 'error' })
        );
        yield put(sendProgressReportFailure());
    }
}

export function* updateReport({ payload }) {
    const { reportObj, id } = payload;

    try {
        const response = yield axios.patch(
            `/api/v1/progress-reports/${id}`,
            reportObj
        );
        yield put(
            setAlert({
                message: `${reportObj.wardName}'s report was updated successfully!! Thank you.`,
                type: 'success',
            })
        );
        yield put(updateProgressReportSuccess(response.data.data.data));
    } catch (err) {
        yield put(
            setAlert({ message: err.response.data.message, type: 'error' })
        );
        yield put(updateProgressReportFailure());
    }
}

export function* approveReport({ payload }) {
    try {
        yield axios.get(`api/v1/progress-reports/${payload}/approve`);
        yield put(
            setAlert({
                message: 'The report has been sent to the client successfully.',
                type: 'success',
            })
        );
        yield put(approveReportSuccess(payload));
    } catch (err) {
        yield put(
            setAlert({ message: err.response.data.message, type: 'error' })
        );
        yield put(approveReportFailure(payload));
    }
}

export function* commentOnReport({ payload }) {
    const { id, comment } = payload;
    try {
        const response = yield axios.post(`api/v1/progress-reports/${id}/comment`, {
            comment,
        });
        yield put(
            setAlert({ message: response.data.data.message, type: 'success' })
        );
        yield put(commentOnReportSuccess(id));
    } catch (err) {
        yield put(
            setAlert({ message: err.response.data.message, type: 'error' })
        );
        yield put(commentOnReportFailure(id));
    }
}

export function* getReports({ payload }) {
    const { search } = payload;

    let url = 'api/v1/progress-reports';

    let query;
    if (search) {
        switch (search.searchType) {
            case 'new':
                query = `tutorName[regex]=${search.searchTerm}&tutorName[options]=i&pending=true`;
                break;
            case 'old':
                query = `tutorName[regex]=${search.searchTerm}&tutorName[options]=i&pending=false`;
                break;
            case 'home':
                query = `familyName[regex]=${search.searchTerm}&familyName[options]=i`;
                break;
            case 'all-new':
                query = 'pending=true&tutorName[ne]=Roberts Odunayo';
                break;
            case 'all-old':
                query = 'pending=false&tutorName[ne]=Roberts Odunayo';
                break;

            default:
                return;
        }
        url = `api/v1/progress-reports?${query}`;
    }

    try {
        const response = yield axios.get(url);

        yield put(getProgressReportsSuccess(response.data));
    } catch (err) {
        yield put(
            setAlert({ message: err.response.data.message, type: 'error' })
        );
        yield put(getProgressReportsFailure());
    }
}

export function* loadMore({ payload }) {
    const { role, pageNo, search } = payload;

    let query;
    if (role === 'admin') {
        switch (search.searchType) {
            case 'new':
                query = `tutorName[regex]=${search.searchTerm}&tutorName[options]=i&pending=true`;
                break;
            case 'old':
                query = `tutorName[regex]=${search.searchTerm}&tutorName[options]=i&pending=false`;
                break;
            case 'home':
                query = `familyName[regex]=${search.searchTerm}&familyName[options]=i`;
                break;
            case 'all-new':
                query = 'pending=true&tutorName[ne]=Roberts Odunayo';
                break;
            case 'all-old':
                query = 'pending=false&tutorName[ne]=Roberts Odunayo';
                break;

            default:
                return;
        }
    }

    const url =
        role === 'admin' ?
        `api/v1/progress-reports?${query}&page=${pageNo}` :
        `api/v1/progress-reports?page=${pageNo}`;

    try {
        const response = yield axios.get(url);
        yield put(loadMoreReportsSuccess(response.data));
    } catch (err) {
        yield put(
            setAlert({ message: err.response.data.message, type: 'error' })
        );
        yield put(loadMoreReportsFailure(pageNo - 1));
    }
}

export function* getRecentProgressReports() {
    try {
        const res1 = yield axios.get(
            '/api/v1/progress-reports?tutorName[ne]=Roberts Odunayo'
        );

        const res2 = yield axios.get(
            '/api/v1/progress-reports?tutorName[ne]=Roberts Odunayo&pending=false&fields=_id'
        );

        yield put(
            getRecentProgressReportsSuccess({
                totalReports: res1.data.totalDocuments,
                totalApproved: res2.data.totalDocuments,
                reports: res1.data.data.data,
            })
        );
    } catch (err) {
        yield put(
            setAlert({ message: err.response.data.message, type: 'error' })
        );
        yield put(getRecentProgressReportsFailure());
    }
}

export function* getPrevCurrentReportsCount() {
    try {
        const res = yield axios.get(
            '/api/v1/progress-reports/get-monthly-num-of-reports'
        );

        yield put(getPrevCurrentReportsCountSuccess(res.data.data.data));
    } catch (err) {
        yield put(
            setAlert({ message: err.response.data.message, type: 'error' })
        );
        yield put(getPrevCurrentReportsCountFailure());
    }
}

export function* onProgressReportSendStart() {
    yield takeLatest(ProgressReportActionTypes.SEND_REPORT_START, sendReport);
}

export function* onProgressReportUpdateStart() {
    yield takeLatest(ProgressReportActionTypes.UPDATE_REPORT_START, updateReport);
}

export function* onApproveReportStart() {
    yield takeEvery(
        ProgressReportActionTypes.APPROVE_REPORT_START,
        approveReport
    );
}

export function* onCommentOnReportStart() {
    yield takeLatest(
        ProgressReportActionTypes.COMMENT_ON_REPORT_START,
        commentOnReport
    );
}

export function* onGetProgressReportsStart() {
    yield takeLatest(
        ProgressReportActionTypes.GET_PROGRESS_REPORTS_START,
        getReports
    );
}

export function* onLoadMoreReportsStart() {
    yield takeLatest(ProgressReportActionTypes.LOAD_MORE_REPORTS_START, loadMore);
}

export function* onGetRecentProgressReportsStart() {
    yield takeLatest(
        ProgressReportActionTypes.GET_RECENT_PROGRESS_REPORTS_START,
        getRecentProgressReports
    );
}

export function* onGetPrevCurrentReportsCountStart() {
    yield takeLatest(
        ProgressReportActionTypes.GET_PREV_CURRENT_REPORTS_COUNT_START,
        getPrevCurrentReportsCount
    );
}

export function* progressReportSagas() {
    yield all([
        call(onProgressReportSendStart),
        call(onProgressReportUpdateStart),
        call(onApproveReportStart),
        call(onCommentOnReportStart),
        call(onGetProgressReportsStart),
        call(onLoadMoreReportsStart),
        call(onGetRecentProgressReportsStart),
        call(onGetPrevCurrentReportsCountStart),
    ]);
}