import { takeLatest, put, call, all, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import TutorActionTypes from './tutor.types';

import { setAlert } from '../app/app.actions';

import {
    addTutorFailure,
    addTutorSuccess,
    getRecentTutorsSuccess,
    loadMoreTutorsFailure,
    loadMoreTutorsSuccess,
    searchTutorsFailure,
    searchTutorsSuccess,
    sendResetPasswordFailure,
    sendResetPasswordSuccess,
    updateTutorFailure,
    updateTutorSuccess,
} from './tutor.actions';

export function* getRecentTutors() {
    try {
        //To get the total tutors and the recent tutors
        const res1 = yield axios.get('api/v1/tutors');

        //To get the total active tutors
        const res2 = yield axios.get('api/v1/tutors?engaged=true&fields=_id');

        const results = {
            totalTutors: res1.data.totalDocuments,
            totalActive: res2.data.totalDocuments,
            data: res1.data.data.data,
        };

        yield put(getRecentTutorsSuccess(results));
    } catch (err) {
        yield put(
            setAlert({ message: err.response.data.message, type: 'error' })
        );
    }
}

export function* addTutor({ payload }) {
    try {
        const res1 = yield axios.post('api/v1/tutors', payload);

        //This helps to populate the tutees field
        const res2 = yield axios.get(`api/v1/tutors/${res1.data.data.data._id}`);

        yield put(addTutorSuccess(res2.data.data.data));
    } catch (err) {
        yield put(addTutorFailure());
        yield put(
            setAlert({ message: err.response.data.message, type: 'error' })
        );
    }
}

export function* updateTutor({ payload }) {
    try {
        const res1 = yield axios.patch(`api/v1/tutors/${payload._id}`, payload);

        //To get the total active tutors again
        const res2 = yield axios.get('api/v1/tutors?engaged=true&fields=_id');

        yield put(
            updateTutorSuccess({
                data: res1.data.data.data,
                totalActive: res2.data.totalDocuments,
            })
        );
    } catch (err) {
        yield put(updateTutorFailure());
        yield put(
            setAlert({ message: err.response.data.message, type: 'error' })
        );
    }
}

export function* searchTutor({ payload }) {
    let query;
    const { searchTerm, searchBy } = payload;

    switch (searchBy) {
        case 'name':
            query = `name[regex]=${searchTerm}&name[options]=i`;
            break;
        case 'fields':
            query = `in[fields]=${searchTerm}`;
            break;
        case 'family':
            query = `students__familyName[regex]=${searchTerm}&students__familyName[options]=i`;
            break;
        case 'engaged':
            query = `engaged=true`;
            break;
        case 'notEngaged':
            query = `engaged=false`;
            break;
        default:
            return;
    }

    try {
        const res = yield axios.get(`/api/v1/tutors?${query}`);

        yield put(searchTutorsSuccess(res.data));
    } catch (err) {
        yield put(searchTutorsFailure());
        yield put(
            setAlert({ message: err.response.data.message, type: 'error' })
        );
    }
}

export function* loadMoreTutors({ payload }) {
    let query;
    const { searchTerm, searchBy, page } = payload;

    switch (searchBy) {
        case 'name':
            query = `name[regex]=${searchTerm}&name[options]=i`;
            break;
        case 'fields':
            query = `in[fields]=${searchTerm}`;
            break;
        case 'family':
            query = `students__familyName[regex]=${searchTerm}&students__familyName[options]=i`;
            break;
        case 'engaged':
            query = `engaged=true`;
            break;
        case 'notEngaged':
            query = `engaged=false`;
            break;
        default:
            return;
    }

    try {
        const res = yield axios.get(`/api/v1/tutors?${query}&page=${page}`);

        yield put(loadMoreTutorsSuccess(res.data));
    } catch (err) {
        yield put(loadMoreTutorsFailure(page - 1));
        yield put(
            setAlert({ message: err.response.data.message, type: 'error' })
        );
    }
}

export function* sendResetPassword({ payload }) {
    const { id, email } = payload;
    try {
        const res = yield axios.post('/api/v1/tutors/reset-password', { email });

        yield put(sendResetPasswordSuccess(id));
        yield put(setAlert({ message: res.data.message, type: 'success' }));
    } catch (err) {
        yield put(sendResetPasswordFailure(id));
        yield put(
            setAlert({ message: err.response.data.message, type: 'error' })
        );
    }
}

export function* onGetRecentTutorsStart() {
    yield takeLatest(TutorActionTypes.GET_RECENT_TUTORS_START, getRecentTutors);
}

export function* onAddTutorStart() {
    yield takeLatest(TutorActionTypes.ADD_TUTOR_START, addTutor);
}

export function* onUpdateTutorStart() {
    yield takeLatest(TutorActionTypes.UPDATE_TUTOR_START, updateTutor);
}

export function* onSearchTutorStart() {
    yield takeLatest(TutorActionTypes.SEARCH_TUTORS_START, searchTutor);
}

export function* onLoadMoreTutorsStart() {
    yield takeLatest(TutorActionTypes.LOAD_MORE_TUTORS_START, loadMoreTutors);
}

export function* onSendResetPasswordStart() {
    yield takeEvery(
        TutorActionTypes.SEND_RESET_PASSWORD_START,
        sendResetPassword
    );
}

export function* tutorSagas() {
    yield all([
        call(onGetRecentTutorsStart),
        call(onAddTutorStart),
        call(onUpdateTutorStart),
        call(onSearchTutorStart),
        call(onLoadMoreTutorsStart),
        call(onSendResetPasswordStart),
    ]);
}