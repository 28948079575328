import uniqid from 'uniqid';
import Popup from 'reactjs-popup';

import { MoreOptionsWrapper, Option } from './more-options.styles';

import Icon from '../icon/icon.component';

const MoreOptions = ({ options, trigger, position, contentZIndex }) => {
  return (
    <Popup
      contentStyle={{ zIndex: contentZIndex }}
      trigger={trigger}
      position={position}
      on={['click']}
      closeOnDocumentClick
      mouseLeaveDelay={300}
      mouseEnterDelay={0}
      arrow={false}
    >
      {(close) => (
        <MoreOptionsWrapper>
          {options.map((option) => (
            <Option
              key={uniqid()}
              onClick={() => {
                option.action();

                return close();
              }}
            >
              <Icon iconName={option.iconName} black />

              <span>{option.label}</span>
            </Option>
          ))}
        </MoreOptionsWrapper>
      )}
    </Popup>
  );
};

export default MoreOptions;
