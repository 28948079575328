const WeeklyChecklistActionTypes = {
    SEND_W_CHECKLIST_START: 'SEND_W_CHECKLIST_START',
    SEND_W_CHECKLIST_SUCCESS: 'SEND_W_CHECKLIST_SUCCESS',
    SEND_W_CHECKLIST_FAILURE: 'SEND_W_CHECKLIST_FAILURE',
    GET_W_CHECKLISTS_START: 'GET_W_CHECKLISTS_START',
    GET_W_CHECKLISTS_SUCCESS: 'GET_W_CHECKLISTS_SUCCESS',
    GET_W_CHECKLISTS_FAILURE: 'GET_W_CHECKLISTS_FAILURE',
    SORT_W_CHECKLISTS: 'SORT_W_CHECKLISTS',
};

export default WeeklyChecklistActionTypes;