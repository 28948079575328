import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LoginFormContainer = styled.div`
  width: 40rem;
  max-width: 80vw;
  max-height: 90%;
  padding: 2rem 5rem;
  border-radius: 1rem;
  background-image: linear-gradient(145deg, #0257ac, #012850);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  overflow: auto;
  scrollbar-width: thin;
`;

export const LoginFormLogo = styled.img`
  width: 70%;
  margin-bottom: 3rem;
`;

export const LoginFormText = styled.p`
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 5rem;
  text-align: center;
`;

export const Form = styled.form`
  width: 100%;
  color: #fff;
`;

export const ForgotLink = styled(Link)`
  margin-top: 4rem;
`;
