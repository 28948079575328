import styled, { css } from 'styled-components';

const formInputContainerCentered = css`
  display: flex;
  justify-content: center;
`;

export const FormInputContainer = styled.div`
  width: 100%;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 5rem;
  }
  ${(props) => (props.centered ? formInputContainerCentered : '')}
`;

export const Input = styled.input`
  font-size: inherit;
  color: inherit;
  width: 100%;
  background: transparent;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.8);
  padding: 1rem;
  &:focus {
    outline: none;
    border-bottom-color: rgba(252, 236, 4, 0.75);
  }
  &:not(:placeholder-shown) {
    &:valid {
      border-bottom-color: #fcec04;
    }
    &:invalid {
      border-bottom-color: red;
    }
  }
  &:focus + label,
  &:not(:placeholder-shown) + label {
    bottom: 3rem;
    opacity: 1;
  }
`;

export const Label = styled.label`
  pointer-events: none;
  display: flex;
  align-items: center;
  padding: 1rem;
  position: absolute;
  bottom: 0rem;
  left: 0;
  opacity: 0.7;
  transition-property: bottom, opacity;
  transition-duration: 0.2s;
  & > span {
    margin-left: 1rem;
  }
`;
