import styled from 'styled-components';

export const MoreOptionsWrapper = styled.ul`
  background-color: #fff;
  list-style: none;
  border-radius: 5px;
  padding: 5px 0;
  margin: 5px;
  box-shadow: 0 1.5px 3px rgba(4, 4, 4, 0.3);
`;

export const Option = styled.li`
  cursor: pointer;
  padding: 8px 3.5rem;
  min-width: fit-content;
  white-space: nowrap;
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 1.5rem;
  }

  &:hover {
    background-color: rgba(112, 112, 112, 0.1);
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(225, 225, 225, 0.8);
  }
`;
