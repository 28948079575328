export const addStudentYear = (arrayWithoutYear, arrayWithYear) => {
    //arrayWithoutYear structure = [{familyName, wardName, familyId}]
    //arrayWithYear structure = [{id, wards:[wardName, wardClass}]]

    return arrayWithoutYear
        .sort((a, b) =>
            a.familyName > b.familyName ?
            1 :
            a.familyName === b.familyName ?
            a.wardName > b.wardName ?
            1 :
            -1 :
            -1
        )
        .map((el) => {
            const familyObject = arrayWithYear.filter(
                (l) => l._id === el.familyId
            )[0];

            const ward = familyObject.wards.filter(
                (l) => l.name.toLowerCase() === el.wardName.toLowerCase()
            )[0];

            return {...el, wardClass: ward.class };
        });
};