import styled from 'styled-components';

import { ToastContainer } from 'react-toastify';

export const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
})`
  @media only screen and (max-width: 31.25em) {
    width: 95vw;
    right: 0;
    margin: 0 auto;

    .toast {
      margin-bottom: 1rem;
    }
  }
`;
