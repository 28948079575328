import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

import userReducer from './user/user.reducer';
import progressReportReducer from './progress-report/progress-report.reducer';
import tutorReducer from './tutor/tutor.reducer';
import appReducer from './app/app.reducer';
import clientReducer from './client/client.reducer';
import weeklyChecklistReducer from './weekly-checklist/weekly-checklist.reducer';
import engagementReducer from './engagements/engagements.reducer';

// const persistConfig = {
//     key: 'root',
//     storage,
// };

const appRootReducer = combineReducers({
    user: userReducer,
    progressReport: progressReportReducer,
    app: appReducer,
    tutor: tutorReducer,
    client: clientReducer,
    weeklyChecklist: weeklyChecklistReducer,
    engagement: engagementReducer
});

const rootReducer = (state, action) => {
    //This helps reset each of the reducers on user sign out
    if (action.type === 'SIGN_OUT_SUCCESS') state = undefined;

    return appRootReducer(state, action);
};

// export default persistReducer(persistConfig, rootReducer);
export default rootReducer;