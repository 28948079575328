import styled, { css } from 'styled-components';

const formGroupRow = css`
  flex-direction: row;
  @media only screen and (max-width: 31.25em) {
    flex-direction: row;
    margin-top: 2.5rem;
  }
`;

const inputContainerHalfWidth = css`
  width: 50%;
  @media only screen and (max-width: 31.25em) {
    width: 100%;
  }
`;

export const OverviewUserDetails = styled.div``;

export const OverviewSubtitle = styled.h3`
  margin-bottom: 3rem;
  font-weight: 400;
  font-size: 2rem;
`;

export const OverviewUserDetailsCard = styled.div`
  border-radius: 5px;
  padding: 3rem;
  background-color: #fff;
  border-width: 1.5px;
  border-style: solid;
  transition-property: border-color, box-shadow;
  transition-duration: 0.2s;
  ${(props) =>
    props.readOnly
      ? 'border-color: transparent; box-shadow: 0 0.8px 1.6px rgba(4, 4, 4, 0.5);'
      : 'border-color: #2c94fc; box-shadow: 0 1.5px 3px rgba(4, 4, 4, 0.5);'}
`;

export const OverviewUserForm = styled.form``;

export const OverviewUserFormGroup = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: 31.25em) {
    align-items: initial;
    flex-direction: column;
    justify-content: center;
    ${(props) =>
      props.children.length > 1 ? '' : 'justify-content: flex-start;'}
  }
  &:not(:last-child) {
    margin-bottom: 3rem;
    @media only screen and (max-width: 31.25em) {
      margin-bottom: 0rem;
    }
  }
  & > button {
    @media only screen and (max-width: 31.25em) {
      padding: 1rem 2.5rem;
    }
    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
  ${(props) => (props.row ? formGroupRow : '')}
`;

export const OverviewUserFormInputContainer = styled.div`
  position: relative;
  flex: auto;
  @media only screen and (max-width: 31.25em) {
    margin-bottom: 1.5rem;
  }
  &:not(:last-child) {
    margin-right: 1.5rem;
    @media only screen and (max-width: 31.25em) {
      margin-right: 0;
    }
  }
  ${(props) => (props.halfWidth ? inputContainerHalfWidth : '')}
`;

export const OverviewUserFormInput = styled.input`
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  outline: none;
  border: 1.5px solid #040404;
  border-radius: 5px;
  padding: 1rem 2rem;
  &[type='number'] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;
    &:focus + label,
    &:not(:placeholder-shown) + label {
      top: 0;
      transform: scale(0.75) translate(-27%, -65%);
      background-color: #fff;
      padding: 0 5px;
      color: #040404;
    }
  }
  &:focus {
    border-color: #2c94fc;
  }
  &:focus + label,
  &:not(:placeholder-shown) + label {
    top: 0;
    transform: scale(0.75) translate(-40%, -65%);
    background-color: #fff;
    padding: 0 5px;
    color: #040404;
    ${(props) =>
      props.location ? 'transform: scale(0.75) translate(-30%, -65%);' : ''}
  }
  ${(props) => (props.readOnly ? 'pointer-events:none;' : '')}
`;

export const OverviewUserFormLabel = styled.label`
  pointer-events: none;
  color: #777777;
  padding: 0;
  font-size: 1.65rem;
  position: absolute;
  top: 50%;
  left: 2rem;
  transform: translateY(-50%);
  transition-property: transform, top, background-color, padding, color;
  transition-duration: 0.2s;
`;
