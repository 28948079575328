const UserActionTypes = {
    EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
    SIGN_OUT_START: 'SIGN_OUT_START',
    SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
    SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',
    CHECK_USER_SESSION: 'CHECK_USER_SESSION',
    SET_CURRENT_USER: 'SET_CURRENT_USER',
    UPDATE_CURRENT_USER_START: 'UPDATE_CURRENT_USER_START',
    UPDATE_CURRENT_USER_SUCCESS: 'UPDATE_CURRENT_USER_SUCCESS',
    UPDATE_CURRENT_USER_FAILURE: 'UPDATE_CURRENT_USER_FAILURE',
    GET_OVERVIEW_STATS_START: 'GET_OVERVIEW_STATS_START',
    GET_OVERVIEW_STATS_SUCCESS: 'GET_OVERVIEW_STATS_SUCCESS',
    GET_OVERVIEW_STATS_FAILURE: 'GET_OVERVIEW_STATS_FAILURE',
    UPDATE_CURRENT_USER_PASSWORD_START: 'UPDATE_CURRENT_USER_PASSWORD_START',
    UPDATE_CURRENT_USER_PASSWORD_SUCCESS: 'UPDATE_CURRENT_USER_PASSWORD_SUCCESS',
    UPDATE_CURRENT_USER_PASSWORD_FAILURE: 'UPDATE_CURRENT_USER_PASSWORD_FAILURE',
};

export default UserActionTypes;