import styled from 'styled-components';

export const LoginPageContainer = styled.main`
  height: 100vh;
  background-image: url(${(props) => props.img}),
    linear-gradient(to right, yellow, green);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
`;
