import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${({ fullScreen }) => fullScreen && 'height: 100vh; width: 100vw;'}
`;

export const Spinner = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #25b09b);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 6px), #000 0);
  animation: s3 1s infinite linear;

  @keyframes s3 {
    to {
      transform: rotate(1turn);
    }
  }
`;

export const BouncyDots = styled.div`
  width: 4.8rem;
  height: 1.8rem;
  background: radial-gradient(circle closest-side, #000 90%, #0000) 0% 50%,
    radial-gradient(circle closest-side, #000 90%, #0000) 50% 50%,
    radial-gradient(circle closest-side, #000 90%, #0000) 100% 50%;
  background-size: calc(100% / 3) 1rem;
  background-repeat: no-repeat;
  animation: d3 1s infinite linear;

  @keyframes d3 {
    20% {
      background-position: 0% 0%, 50% 50%, 100% 50%;
    }
    40% {
      background-position: 0% 100%, 50% 0%, 100% 50%;
    }
    60% {
      background-position: 0% 50%, 50% 100%, 100% 0%;
    }
    80% {
      background-position: 0% 50%, 50% 50%, 100% 100%;
    }
  }
`;

export const LoadingText = styled.p`
  margin: 1.5rem;
`;
