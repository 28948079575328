import ProgressReportActionTypes from './progress-report.types';

import { filterById, returnFalse, sortReports } from './progress-report.utils';

const INITIAL_STATE = {
    foundProgressReports: [],
    progressReports: {},
    totalDocuments: 0,
    totalResults: 0,
    gettingReports: false,
    gottenReports: false,
    adminSearch: {},
    sendingReport: false,
    sentReport: false,
    updatingReport: false,
    updatedReport: false,
    reportToEdit: null,
    approvingReport: {},
    commentingOnReport: false,
    commentedOnReport: false,
    currentPage: 1,
    loadingMore: false,
    totalReports: 0,
    totalApproved: 0,
    gettingRecentReports: false,
    gottenRecentReports: false,
    recentProgressReports: [],
    gettingPrevCurrentReportsCount: false,
    gottenPrevCurrentReportsCount: false,
    prevReportsCount: 0,
    currentReportsCount: 0,
};

const progressReportReducer = (currentState = INITIAL_STATE, action) => {
    switch (action.type) {
        case ProgressReportActionTypes.SEND_REPORT_START:
            return {
                ...currentState,
                sendingReport: true,
                sentReport: false,
            };
        case ProgressReportActionTypes.UPDATE_REPORT_START:
            return {
                ...currentState,
                updatingReport: true,
                updatedReport: false,
            };
        case ProgressReportActionTypes.APPROVE_REPORT_START:
            return {
                ...currentState,
                approvingReport: {
                    ...currentState.approvingReport,
                    [action.payload]: true,
                },
            };
        case ProgressReportActionTypes.COMMENT_ON_REPORT_START:
            return {
                ...currentState,
                commentingOnReport: true,
                commentedOnReport: false,
            };
        case ProgressReportActionTypes.GET_PROGRESS_REPORTS_START:
            return {
                ...currentState,
                gettingReports: true,
                adminSearch: action.payload.search,
                gottenReports: false,
                currentPage: 1,
            };
        case ProgressReportActionTypes.LOAD_MORE_REPORTS_START:
            return {
                ...currentState,
                currentPage: action.payload.pageNo,
                loadingMore: true,
            };
        case ProgressReportActionTypes.GET_RECENT_PROGRESS_REPORTS_START:
            return {
                ...currentState,
                gettingRecentReports: true,
            };
        case ProgressReportActionTypes.GET_PREV_CURRENT_REPORTS_COUNT_START:
            return {
                ...currentState,
                gettingPrevCurrentReportsCount: true,
            };
        case ProgressReportActionTypes.SEND_REPORT_SUCCESS:
            const reports = [
                action.payload.report,
                ...currentState.foundProgressReports,
            ].filter((el, i) => i < 10);

            return {
                ...currentState,
                sendingReport: false,
                sentReport: true,
                totalDocuments: currentState.gottenReports ?
                    currentState.totalDocuments + 1 :
                    currentState.totalDocuments,
                totalResults: currentState.gottenReports ?
                    reports.length :
                    currentState.totalResults,
                foundProgressReports: currentState.gottenReports && action.payload.role === 'tutor' ?
                    [...reports] :
                    [...currentState.foundProgressReports],
                currentPage: 1,
            };
        case ProgressReportActionTypes.UPDATE_REPORT_SUCCESS:
            return {
                ...currentState,
                updatingReport: false,
                updatedReport: true,
                reportToEdit: null,
                foundProgressReports: [
                    ...currentState.foundProgressReports.map((report) =>
                        report._id === action.payload._id ? {...action.payload } : report
                    ),
                ],
            };
        case ProgressReportActionTypes.APPROVE_REPORT_SUCCESS:
            return {
                ...currentState,
                approvingReport: {
                    ...currentState.approvingReport,
                    [action.payload]: false,
                },
                totalDocuments: currentState.totalDocuments - 1,
                totalResults: currentState.totalResults - 1,
                foundProgressReports: [
                    ...filterById(currentState.foundProgressReports, action.payload),
                ],
                recentProgressReports: [
                    ...currentState.recentProgressReports.map((report) =>
                        report._id === action.payload ?
                        {...report, comment: false } :
                        report
                    ),
                ],
                totalApproved: currentState.totalApproved + 1,
            };
        case ProgressReportActionTypes.COMMENT_ON_REPORT_SUCCESS:
            return {
                ...currentState,
                commentingOnReport: false,
                commentedOnReport: true,
                foundProgressReports: [
                    ...currentState.foundProgressReports.map((report) =>
                        report._id === action.payload ?
                        {...report, comment: true } :
                        report
                    ),
                ],
                recentProgressReports: [
                    ...currentState.recentProgressReports.map((report) =>
                        report._id === action.payload ?
                        {...report, comment: true } :
                        report
                    ),
                ],
            };
        case ProgressReportActionTypes.GET_PROGRESS_REPORTS_SUCCESS:
            return {
                ...currentState,
                gettingReports: false,
                gottenReports: true,
                totalDocuments: action.payload.totalDocuments,
                totalResults: action.payload.results,
                foundProgressReports: [...action.payload.data.data],
            };
        case ProgressReportActionTypes.LOAD_MORE_REPORTS_SUCCESS:
            return {
                ...currentState,
                foundProgressReports: [
                    ...currentState.foundProgressReports,
                    ...action.payload.data.data,
                ],
                totalResults: currentState.totalResults + action.payload.results >
                    currentState.totalDocuments ?
                    currentState.totalDocuments :
                    currentState.totalResults + action.payload.results,
                loadingMore: false,
            };
        case ProgressReportActionTypes.GET_RECENT_PROGRESS_REPORTS_SUCCESS:
            return {
                ...currentState,
                gettingRecentReports: false,
                gottenRecentReports: true,
                recentProgressReports: [...action.payload.reports],
                totalReports: action.payload.totalReports,
                totalApproved: action.payload.totalApproved,
            };
        case ProgressReportActionTypes.GET_PREV_CURRENT_REPORTS_COUNT_SUCCESS:
            return {
                ...currentState,
                gettingPrevCurrentReportsCount: false,
                gottenPrevCurrentReportsCount: true,
                prevReportsCount: action.payload.countPrev,
                currentReportsCount: action.payload.countCurrent,
            };
        case ProgressReportActionTypes.LOAD_LESS_REPORTS:
            const filteredReports = currentState.foundProgressReports.filter(
                (el, i) => i < 10
            );
            return {
                ...currentState,
                foundProgressReports: [...filteredReports],
                totalResults: filteredReports.length,
                currentPage: 1,
            };
        case ProgressReportActionTypes.SORT_REPORTS:
            return {
                ...currentState,
                progressReports: {...sortReports(action.payload.reports) },
                approvingReport: action.payload.role === 'admin' ?
                    {...returnFalse(action.payload.reports) } :
                    {},
            };
        case ProgressReportActionTypes.SET_REPORT_TO_EDIT:
            return {
                ...currentState,
                reportToEdit: action.payload,
            };
        case ProgressReportActionTypes.CLEAR_REPORTS:
            return {
                ...currentState,
                progressReports: {},
                foundProgressReports: [],
                totalDocuments: 0,
                totalResults: 0,
                adminSearch: {},
                gottenReports: false,
                approvingReport: {},
            };
        case ProgressReportActionTypes.SEND_REPORT_FAILURE:
        case ProgressReportActionTypes.UPDATE_REPORT_FAILURE:
            return {
                ...currentState,
                sendingReport: false,
                sentReport: false,
                updatingReport: false,
                updatedReport: false,
                approvingReport: false,
                commentingOnReport: false,
            };
        case ProgressReportActionTypes.APPROVE_REPORT_FAILURE:
            return {
                ...currentState,
                approvingReport: {
                    ...currentState.approvingReport,
                    [action.payload]: false,
                },
            };
        case ProgressReportActionTypes.COMMENT_ON_REPORT_FAILURE:
            return {
                ...currentState,
                commentingOnReport: false,
                commentedOnReport: false,
            };
        case ProgressReportActionTypes.GET_PROGRESS_REPORTS_FAILURE:
            return {
                ...currentState,
                foundProgressReports: [],
                totalDocuments: 0,
                totalResults: 0,
                gettingReports: false,
                gottenReports: false,
            };
        case ProgressReportActionTypes.LOAD_MORE_REPORTS_FAILURE:
            return {
                ...currentState,
                currentPage: action.payload - 1,
                loadingMore: false,
            };
        case ProgressReportActionTypes.GET_RECENT_PROGRESS_REPORTS_FAILURE:
            return {
                ...currentState,
                gottenRecentReports: false,
                gettingRecentReports: false,
                recentProgressReports: [],
                totalApproved: 0,
                totalReports: 0,
            };
        case ProgressReportActionTypes.GET_PREV_CURRENT_REPORTS_COUNT_FAILURE:
            return {
                ...currentState,
                gettingPrevCurrentReportsCount: false,
                gottenPrevCurrentReportsCount: false,
            };

        default:
            return currentState;
    }
};

export default progressReportReducer;