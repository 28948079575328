import ClientActionTypes from './client.types';

export const getRecentClientsStart = () => ({
    type: ClientActionTypes.GET_RECENT_CLIENTS_START,
});

export const getRecentClientsSuccess = (results) => ({
    type: ClientActionTypes.GET_RECENT_CLIENTS_SUCCESS,
    payload: results,
});

export const getRecentClientsFailure = () => ({
    type: ClientActionTypes.GET_RECENT_CLIENTS_FAILURE,
});

export const addClientStart = (client) => ({
    type: ClientActionTypes.ADD_CLIENT_START,
    payload: client,
});

export const addClientSuccess = (client) => ({
    type: ClientActionTypes.ADD_CLIENT_SUCCESS,
    payload: client,
});

export const addClientFailure = () => ({
    type: ClientActionTypes.ADD_CLIENT_FAILURE,
});

export const setAddedToFalse = () => ({
    type: ClientActionTypes.SET_ADDED_TO_FALSE,
});

export const updateClientStart = (client) => ({
    type: ClientActionTypes.UPDATE_CLIENT_START,
    payload: client,
});

export const updateClientSuccess = (response) => ({
    type: ClientActionTypes.UPDATE_CLIENT_SUCCESS,
    payload: response,
});

export const updateClientFailure = () => ({
    type: ClientActionTypes.UPDATE_CLIENT_FAILURE,
});

export const setUpdatedToFalse = () => ({
    type: ClientActionTypes.SET_UPDATED_TO_FALSE,
});

export const searchClientsStart = (search) => ({
    type: ClientActionTypes.SEARCH_CLIENTS_START,
    payload: search,
});

export const searchClientsSuccess = (results) => ({
    type: ClientActionTypes.SEARCH_CLIENTS_SUCCESS,
    payload: results,
});

export const searchClientsFailure = () => ({
    type: ClientActionTypes.SEARCH_CLIENTS_FAILURE,
});

export const clearSearchClients = () => ({
    type: ClientActionTypes.CLEAR_SEARCH_CLIENTS,
});

export const loadMoreClientsStart = (query) => ({
    type: ClientActionTypes.LOAD_MORE_CLIENTS_START,
    payload: query,
});

export const loadMoreClientsSuccess = (results) => ({
    type: ClientActionTypes.LOAD_MORE_CLIENTS_SUCCESS,
    payload: results,
});

export const loadMoreClientsFailure = (pageNo) => ({
    type: ClientActionTypes.LOAD_MORE_CLIENTS_FAILURE,
    payload: pageNo,
});

export const loadLessClients = () => ({
    type: ClientActionTypes.LOAD_LESS_CLIENTS,
});