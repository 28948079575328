import styled from 'styled-components';

export const Main = styled.main`
  background-color: #eee;
  min-height: 100vh;
  position: relative;
  top: 11rem;
  left: 29rem;
  width: calc(100% - 29rem);
  padding: 3rem;
  @media only screen and (max-width: 62.5em) {
    left: 0;
    width: 100%;
  }
  @media only screen and (max-width: 22.5em) {
    padding: 2rem;
  }
`;

export const Title = styled.h2`
  font-size: 2.5rem;
  position: fixed;
  top: 6rem;
  background-color: #f8f8f8;
  width: calc(100vw - 29rem);
  padding: 1rem 3rem;
  margin-left: -3rem;
  z-index: 2;
  box-shadow: 0 0.5px 1px rgba(4, 4, 4, 0.5);
  @media only screen and (max-width: 62.5em) {
    width: 100vw;
  }
  @media only screen and (max-width: 22.5em) {
    margin-left: -2rem;
  }
`;
