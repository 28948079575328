import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HeaderContainer = styled.header`
  /* background-image: linear-gradient(135deg, #0257ac, #012850); */
  background-color: #fff;
  width: 100vw;
  display: flex;
  align-items: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  padding: 5px 4rem;
  /* box-shadow: 0 1.5px 3px rgba(4, 4, 4, 0.5); */
  justify-content: space-between;
  z-index: 9;
  @media only screen and (max-width: 62.5em) {
    padding: 5px 1rem;
  }
  @media only screen and (max-width: 25em) {
    padding: 5px 1.5rem;
  }
`;

export const HeaderLogo = styled.img`
  height: 5rem;
  @media only screen and (max-width: 62.5em) {
    margin: 0 auto;
  }
`;

export const Header = styled.div`
  display: flex;
`;

export const HeaderUser = styled.div`
  margin-right: 1rem;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

export const HeaderText = styled.p`
  margin-left: 1rem;
  color: #040404;

  @media only screen and (max-width: 25em) {
    display: none;
  }
`;

export const HeaderLogout = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
