import { createSelector } from 'reselect';

import { addStudentYear } from './user.utils';

const selectUser = (state) => state.user;

export const selectCurrentUser = createSelector(
    [selectUser],
    (user) => user.currentUser
);

export const selectTutees = createSelector([selectCurrentUser], (currentUser) =>
    currentUser.role !== 'client' ? currentUser.tutees : null
);

export const selectStudents = createSelector(
    [selectCurrentUser],
    (currentUser) => (currentUser.role !== 'client' ? currentUser.students : null)
);

export const selectTotalStudents = createSelector(
    [selectStudents],
    (students) => students.length
);

export const selectStudentsWithClassYear = createSelector(
    [selectStudents, selectTutees],
    (students, tutees) => addStudentYear(students, tutees)
);