//The array of months is kept in every function to make sure it is empty for each function call

export const sortReports = (reportsToSort) => {
    const timeline = {};

    reportsToSort.forEach((el) => {
        const date = new Date(el.startDate);
        const year = date.getFullYear();
        const month = date.getMonth();

        //Add year to timeline if it isn't already there (the array of arrays is added this way to prevent JS from thinking the arrays are the same)
        if (!Object.keys(timeline).includes(`${year}`))
            timeline[year] = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                []
            ];

        timeline[year][11 - month].push(el);
    });

    return timeline;
};

export const returnFalse = (array) => {
    const o = {};

    array.forEach((el) => (o[el._id] = false));

    return o;
};

export const filterById = (array, id) => {
    return array.filter((el) => el._id !== id);
};