import styled from 'styled-components';

export const HamburgerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 2rem;
  transform: translateY(-50%);
  background-color: transparent;
  height: 2.5rem;
  width: 3rem;
  z-index: 8;
  cursor: pointer;
  transition-property: visibility, opacity;
  transition-duration: 0.1s;
  visibility: hidden;
  opacity: 0;
  @media only screen and (max-width: 62.5em) {
    visibility: visible;
    opacity: 1;
  }
`;

export const HamburgerIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3rem;
  height: 3px;
  background-image: linear-gradient(145deg, #0257ac, #012850);
  z-index: 8;
  ${(props) => (props.active ? 'background-image: none;' : '')}
  transition: background-color 0.1s;
  &::before,
  &::after {
    content: '';
    background-image: linear-gradient(145deg, #0257ac, #012850);
    height: 100%;
    width: 100%;
    position: absolute;
    transition: transform 0.2s, top 0.2s;
  }
  &::before {
    top: -1rem;
    left: 0;
    ${(props) => (props.active ? 'top:0;transform:rotate(45deg)' : '')}
  }
  &::after {
    top: 1rem;
    left: 0;
    ${(props) => (props.active ? 'top:0;transform:rotate(135deg)' : '')}
  }
`;
